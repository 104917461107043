import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	CaretDownIcon,
	CaretSortIcon,
	CaretUpIcon,
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	DotsVerticalIcon,
	DrawingPinFilledIcon,
	DrawingPinIcon,
} from "@radix-ui/react-icons";

import { useIsMobile } from "@metronome/hooks/useResponsive";
import Select from "react-select";

import {
	type Column,
	type ColumnOrderState,
	type HeaderGroup,
	type PaginationState,
	type Row,
	type Table,
	flexRender,
} from "@tanstack/react-table";
import { isValid } from "date-fns/isValid";
import { parseISO } from "date-fns/parseISO";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCSVDownloader } from "react-papaparse";

import {
	type SavedTable,
	useCreateSavedTableViews,
	useUpdateSavedTableViews,
} from "@metronome/api/useSaveTableViews";
import type { IModelReference } from "@metronome/types/ModelReference";
import type { StepInstanceContext } from "@metronome/types/StepInstance";

import { groupBy } from "@metronome/utils/arrayUtils";

import {
	useCreateWorkspaceViews,
	useUpdateWorkspaceViews,
} from "@metronome/api/useWorkspaceViews";
import { ActivitiesCustomFilters } from "@metronome/features/ActivitiesCustomFilters";
import { formatCSVExport } from "@metronome/utils/formatCSVExport";

import Button from "../Button";
import {
	CollapsibleContent,
	CollapsibleRoot,
	CollapsibleTrigger,
} from "../Collapsible";
import DebouncedInput from "../DebouncedInput";
import { FilterDateRange } from "../FilterDateRange";
import { FilterModal } from "../FilterModal";
import SectionMessage from "../SectionMessage";
import { Button as ButtonFilled } from "../ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from "../ui/input";

import styles from "./dataGrid.module.scss";
import type { ActiveView } from "@metronome/types/UserPreference";

function isLastColumn<T>(
	headerGroup: HeaderGroup<T>,
	headerIndex: number,
): boolean {
	if (headerGroup.headers.length === headerIndex + 1) return true;
	return false;
}

function isFirstNonPinnedColumn<T>(
	headerGroup: HeaderGroup<T>,
	headerId: string,
): boolean {
	const headers = headerGroup.headers.filter(
		(header) => !header.column.getIsPinned(),
	);
	return headerId === headers[0].id;
}

const PAGINATION = [10, 25, 50, 100];

const ToggleInput = <T,>({
	column,
}: {
	column: Column<T, unknown>;
}): JSX.Element => (
	<div key={column.id} className="flex flex-row gap-1">
		<input
			{...{
				type: "checkbox",
				checked: column.getIsVisible(),
				onChange: column.getToggleVisibilityHandler(),
			}}
		/>{" "}
		{flexRender(column.columnDef.header, {})}
	</div>
);

interface SidePanelProps<T> {
	powerMode: boolean;
	instance: Table<T>;
	setPowerMode: (powerMode: boolean) => void;
	preferenceKey: string;
	activeView?: ActiveView;
	savedViews?: SavedTable[];
	workspaceViews?: SavedTable[];
	dirty?: boolean;
	setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

function SidePanel<T>({
	instance,
	powerMode,
	setPowerMode,
	activeView,
	preferenceKey,
	savedViews,
	workspaceViews,
	dirty,
	setDirty,
}: SidePanelProps<T>): JSX.Element {
	const intl = useIntl();

	const standardInputs = instance
		.getAllLeafColumns()
		.filter((col) => !col.columnDef?.meta?.treeName);

	const renderStandardInputs = standardInputs.map((column) => (
		<ToggleInput key={column.id} column={column} />
	));

	const metadata = groupBy(
		instance.getAllLeafColumns().filter((col) => col.columnDef?.meta?.treeName),
		(col) => col.columnDef?.meta?.treeName ?? "",
	);

	const groupedMetaInputs = Object.entries(metadata).map(([k, v]) => (
		<label key={k} htmlFor={k} className="flex flex-col">
			<CollapsibleRoot>
				<CollapsibleTrigger className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180">
					<ChevronDownIcon className="h-4 w-4 inline-block shrink-0 text-muted-foreground transition-transform duration-200" />
					<strong className="line-clamp-1 text-start">{k}</strong>
				</CollapsibleTrigger>
				<CollapsibleContent className="ms-6">
					{v.map((column) => (
						<ToggleInput key={column.id} column={column} />
					))}
				</CollapsibleContent>
			</CollapsibleRoot>
		</label>
	));

	const currentViewName = useMemo(() => {
		if (activeView?.type === "workspaceView")
			return workspaceViews?.find((view) => view.id === activeView.id)?.name;
		if (activeView?.type === "userView")
			return savedViews?.find((view) => view.id === activeView.id)?.name;
	}, [activeView, savedViews, workspaceViews]);

	const { mutate: updateTableView } = useUpdateSavedTableViews(preferenceKey);
	const { mutate: createTableView } = useCreateSavedTableViews(preferenceKey);

	const { mutate: updateWorkspaceView } =
		useUpdateWorkspaceViews(preferenceKey);
	const { mutate: createWorkspaceView } =
		useCreateWorkspaceViews(preferenceKey);

	const [viewName, setViewName] = useState("");
	const [enableCompanyViews, setEnableCompanyViews] = useState(false);

	const onSaveView = useCallback(() => {
		createTableView({
			settings: {
				columnFilters: instance.getState().columnFilters,
				columnVisibility: instance.getState().columnVisibility,
				columnPinning: instance.getState().columnPinning,
				columnOrder: instance.getState().columnOrder,
			},
			name: viewName,
			successCallback: () => {
				setViewName("");
			},
		});
		if (setDirty) setDirty(false);
	}, [createTableView, instance, setDirty, viewName]);

	const onSaveWorkspaceView = useCallback(() => {
		createWorkspaceView({
			settings: {
				columnFilters: instance.getState().columnFilters,
				columnVisibility: instance.getState().columnVisibility,
				columnPinning: instance.getState().columnPinning,
				columnOrder: instance.getState().columnOrder,
			},
			name: viewName,
			successCallback: () => {
				setViewName("");
			},
		});
		if (setDirty) setDirty(false);
	}, [createWorkspaceView, instance, setDirty, viewName]);

	const onUpdateView = useCallback(() => {
		if (powerMode && dirty && activeView && currentViewName) {
			if (activeView.type === "userView") {
				updateTableView({
					data: {
						name: currentViewName,
						settings: {
							columnFilters: instance.getState().columnFilters,
							columnVisibility: instance.getState().columnVisibility,
							columnPinning: instance.getState().columnPinning,
							columnOrder: instance.getState().columnOrder,
						},
					},
					key: activeView.id,
				});
			}
			if (activeView.type === "workspaceView") {
				updateWorkspaceView({
					data: {
						name: currentViewName,
						settings: {
							columnFilters: instance.getState().columnFilters,
							columnVisibility: instance.getState().columnVisibility,
							columnPinning: instance.getState().columnPinning,
							columnOrder: instance.getState().columnOrder,
						},
					},
					key: activeView.id,
				});
			}
		}
		if (setDirty) setDirty(false);
		setPowerMode(!powerMode);
	}, [
		powerMode,
		dirty,
		activeView,
		setDirty,
		setPowerMode,
		updateTableView,
		currentViewName,
		instance,
		updateWorkspaceView,
	]);

	return (
		<>
			{!!powerMode && (
				<div
					className={
						"flex flex-col p-2 items-start gap-2 bg-white h-full overflow-auto min-w-48"
					}
				>
					{!!savedViews && !activeView?.id && (
						<div className="flex flex-col gap-2 flex-wrap mt-2">
							{dirty && (
								<SectionMessage appearance="warning">
									<FormattedMessage id="CREATE_A_VIEW_T0_SAVE_CHANGES" />
								</SectionMessage>
							)}

							<div className="group flex row items-center justify-between">
								<h4 className="text-sm font-medium leading-none capitalize">
									<FormattedMessage id="SAVE_CONFIGURE" />
								</h4>
								<DropdownMenu>
									<DropdownMenuTrigger className="text-transparent group-hover:text-slate-500 active:text-slate-500 focus:text-slate-500">
										<DotsVerticalIcon />
									</DropdownMenuTrigger>
									<DropdownMenuContent>
										<DropdownMenuItem
											onSelect={() => setEnableCompanyViews(true)}
										>
											<FormattedMessage id="ENABLE_COMPANY_WIDE_VIEWS" />
										</DropdownMenuItem>
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
							<div className="flex flex-row gap-2 items-center mt-2 flex-wrap">
								<Input
									placeholder={intl.formatMessage({ id: "NAME_OF_THE_VIEW" })}
									value={viewName}
									onChange={(e) => setViewName(e.target.value)}
									minLength={1}
									className="min-w-14"
								/>
								{enableCompanyViews ? (
									<DropdownMenu>
										<DropdownMenuTrigger asChild>
											<ButtonFilled disabled={!viewName} variant="outline">
												<FormattedMessage id="SAVE" />
											</ButtonFilled>
										</DropdownMenuTrigger>
										<DropdownMenuContent>
											<DropdownMenuItem onSelect={onSaveView}>
												<FormattedMessage id="SAVE_VIEW" />
											</DropdownMenuItem>

											<DropdownMenuItem onSelect={onSaveWorkspaceView}>
												<FormattedMessage id="SAVE_COMPANY_VIEW" />
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								) : (
									<ButtonFilled
										variant="outline"
										disabled={!viewName}
										onClick={onSaveView}
									>
										<FormattedMessage id="SAVE_VIEW" />
									</ButtonFilled>
								)}
							</div>
						</div>
					)}

					{!!activeView?.id && (
						<div style={{ maxWidth: "235px" }} className="flex flex-col w-full">
							<div className="truncate flex flex-col">
								<h4 className="text-xs font-medium uppercase leading-none text-slate-500">
									<FormattedMessage id="ACTIVE_VIEW" />
								</h4>
								<div className="flex row items-center gap-2">
									<p className="text-md font-medium">{currentViewName}</p>
								</div>
							</div>
							<div className="flex flex-row gap-2 items-center justify-between">
								{dirty && activeView?.id && (
									<ButtonFilled onClick={onUpdateView}>
										<FormattedMessage id="SAVE" />
									</ButtonFilled>
								)}
							</div>
						</div>
					)}

					{/* <Separator className="my-4" /> */}
					<div className="font-bold text-nowrap">
						<FormattedMessage id="COLUMN_VISIBILITY" />
					</div>

					<label htmlFor="toggle">
						<input
							{...{
								type: "checkbox",
								checked: instance.getIsAllColumnsVisible(),
								onChange: instance.getToggleAllColumnsVisibilityHandler(),
							}}
						/>{" "}
						<FormattedMessage id="TOGGLE_ALL" />
					</label>

					<div className="mt-2">{renderStandardInputs}</div>
					<div className="mt-2 flex flex-col gap-4">{groupedMetaInputs}</div>
				</div>
			)}
		</>
	);
}

/**
 * todo: split this component into smaller components
 */
const Filter = ({
	column,
	firstValue,
}: {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	column: Column<any, unknown>;
	firstValue: unknown;
}): JSX.Element => {
	const intl = useIntl();
	const uniqueValues = column.getFacetedUniqueValues();
	const sortedUniqueValues = React.useMemo(
		() => Array.from(uniqueValues.keys()).sort((a, b) => a - b),
		[uniqueValues],
	);
	const columnFilterValue = column.getFilterValue();

	if (column.columnDef?.meta?.filterComponent) {
		const FilterComponent = column.columnDef?.meta?.filterComponent;
		return <FilterComponent column={column} />;
	}

	if (column.columnDef?.meta?.nodeFilterComponent) {
		const FilterComponent = column.columnDef?.meta?.nodeFilterComponent;
		return <FilterComponent column={column} />;
	}

	if (column.columnDef?.meta?.partyFilterComponent) {
		const FilterComponent = column.columnDef?.meta?.partyFilterComponent;
		return (
			<FilterComponent
				value={column.getFilterValue() as string[]}
				setValue={column.setFilterValue}
			/>
		);
	}

	if (column.columnDef?.meta?.stepFilterComponent) {
		const FilterComponent = column.columnDef?.meta?.stepFilterComponent;
		return <FilterComponent column={column} />;
	}

	let isDate = column.columnDef.meta?.type === "date";
	try {
		if (typeof firstValue === "string") {
			const parsedDate = parseISO(firstValue);
			isDate = isValid(parsedDate);
		}
	} catch {
		isDate = false;
	}

	if (isDate) {
		return (
			<FilterDateRange
				value={column.getFilterValue() as [string, string]}
				setValue={(val) => column.setFilterValue(() => val)}
			/>
		);
	}

	if (Array.isArray(column.columnDef?.meta?.modelRefArray)) {
		const options = column.columnDef?.meta?.modelRefArray
			.filter((v) => !!v)
			.map((modelRef: IModelReference) => ({
				value: modelRef.id,
				label: modelRef.name,
			}));
		const value = Array.isArray(columnFilterValue)
			? columnFilterValue
			: [columnFilterValue];
		const selectedFilters = options?.filter((option) =>
			value.includes(option.value),
		);
		return (
			<div className="mt-2 me-2 mb-24">
				<Select
					value={selectedFilters}
					maxMenuHeight={90}
					onChange={(val) => {
						column.setFilterValue(val?.map((v) => v.value));
					}}
					isMulti
					options={options}
				/>
				{selectedFilters && selectedFilters?.length > 0 && (
					<div className="flex flex-row justify-between mt-2">
						{selectedFilters.length === 1 ? (
							<>
								{selectedFilters.length}{" "}
								<FormattedMessage id="FILTER_APPLIED" />
							</>
						) : (
							<>
								{selectedFilters.length}{" "}
								<FormattedMessage id="FILTERS_APPLIED" />
							</>
						)}
						<Button
							appearance="default"
							onClick={() => {
								column.setFilterValue(undefined);
							}}
						>
							<FormattedMessage id="CLEAR_FILTERS" />
						</Button>
					</div>
				)}
			</div>
		);
	}

	if (!sortedUniqueValues.length) return <span />;
	return (
		<div className="mt-2 me-2">
			<select
				value={columnFilterValue as string}
				className={styles.select}
				onChange={(event) => {
					column.setFilterValue(event?.target.value);
				}}
				id={`${column.id}list`}
			>
				<option value="">{intl.formatMessage({ id: "FILTER" })}</option>
				{[...new Set(sortedUniqueValues.flat())]
					.filter((v) => !!v)
					.map((value: string) => (
						<option value={value} key={value}>
							{value}
						</option>
					))}
			</select>
		</div>
	);
};

interface MobileProps<T> {
	instance: Table<T>;
	goToItem: (contentItem: T) => void;
}

function MobileCard<T>({ instance, goToItem }: MobileProps<T>): JSX.Element {
	return (
		<div className="flex flex-col gap-8 p-1">
			{instance.getRowModel()?.rows?.map((row) => (
				<div key={row.id} className={styles.resetButton}>
					<div className="flex flex-col gap-2">
						<Button appearance="link" onClick={() => goToItem(row.original)}>
							<span className="flex flex-row gap-2 items-center justify-end p-1">
								<FormattedMessage id="VIEW" />
								<FontAwesomeIcon icon={["fas", "arrow-up-right-from-square"]} />
							</span>
						</Button>
						{row.getVisibleCells().map((cell) => (
							<div
								className="flex flex-row gap-2 items-center justify-between"
								key={cell.id}
							>
								<strong className="font-semibold">
									{flexRender(cell.column.columnDef.header, cell.getContext())}:
								</strong>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
}

type CustomFilters<T> = {
	instance: Table<T>;
	context?: StepInstanceContext;
};
function TableCustomFilters<T>({
	instance,
	context,
}: CustomFilters<T>): JSX.Element | null {
	if (context === "activities")
		return <ActivitiesCustomFilters<T> instance={instance} />;
	return null;
}

interface TableHeaderProps<T> {
	instance: Table<T>;
	globalFilter?: string;
	isLoading: boolean;
	context?: StepInstanceContext;
	children?: React.ReactNode;
}

function TableHeader<T>({
	instance,
	isLoading,
	context,
	globalFilter,
	children,
}: TableHeaderProps<T>): JSX.Element {
	const intl = useIntl();
	const nbOfColumnsSorted = instance.getState()?.sorting?.length;
	const nbOfColumnsFiltered = instance.getState()?.columnFilters?.length;

	return (
		<div>
			<TableCustomFilters<T>
				key="filter"
				instance={instance}
				context={context}
			/>
			<div className="flex justify-between items-center my-2 gap-2 flex-wrap">
				{children}
				<div className="flex flex-row items-center gap-4 ms-auto">
					{!!nbOfColumnsSorted && (
						<Button appearance="link" onClick={() => instance.resetSorting()}>
							<FormattedMessage id="RESET_SORTING" />
							{` (${nbOfColumnsSorted})`}
						</Button>
					)}
					{!!nbOfColumnsFiltered && (
						<Button
							appearance={"link"}
							onClick={() => instance.resetColumnFilters()}
						>
							<FormattedMessage id="RESET_COLUMN_FILTERS" />
							{` (${nbOfColumnsFiltered})`}
						</Button>
					)}
				</div>
				<DebouncedInput
					isLoading={isLoading}
					value={globalFilter ?? ""}
					onChange={(value) => {
						instance.setPagination((old) => ({
							pageIndex: 0,
							pageSize: old.pageSize,
						}));
						instance.setGlobalFilter(String(value));
					}}
					placeholder={intl.formatMessage({ id: "SEARCH_ALL_COLUMNS" })}
				/>
			</div>
		</div>
	);
}

interface TableProps<T> {
	instance: Table<T>;
	goToItem: (contentItem: T) => void;
	powerMode?: boolean;
	isFetching?: boolean;
	isFetched?: boolean;
}
function TableBody<T>({
	instance,
	powerMode,
	goToItem,
	isFetched,
}: TableProps<T>): JSX.Element {
	const isMobile = useIsMobile();
	const updateColumnOrder = (
		id: string,
		toRight: boolean,
	): ColumnOrderState => {
		const columnOrder = instance.getState().columnOrder;
		const currentPosition = columnOrder.indexOf(id);
		if (currentPosition === -1) return columnOrder;
		columnOrder.splice(
			toRight ? currentPosition + 1 : currentPosition - 1,
			0,
			columnOrder.splice(currentPosition, 1)[0],
		);
		return [...columnOrder];
	};
	if (isMobile)
		return <MobileCard<T> instance={instance} goToItem={goToItem} />;

	return (
		<table
			style={{ width: instance.getCenterTotalSize() }}
			className={styles.table}
		>
			<thead className="border-b">
				{instance.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header, headerIndex) => (
							<th
								key={header.id}
								colSpan={header.colSpan}
								className={styles.th}
							>
								<div className="flex flex-row items-center gap-2 px-2">
									{!!powerMode &&
										!isFirstNonPinnedColumn(headerGroup, header.id) &&
										header.column.getIsPinned() !== "left" && (
											<button
												type="button"
												className="ms-auto p-0.5 aspect-square text-slate-500 hover:text-blue-700 hover:bg-blue-100 rounded-full"
												onClick={() => {
													const newOrder = updateColumnOrder(
														header.column.id,
														false,
													);
													instance.setColumnOrder(newOrder);
												}}
											>
												<ChevronLeftIcon />
											</button>
										)}
									{!!powerMode &&
										!header.isPlaceholder &&
										header.column.getCanPin() && (
											<div className="flex gap-1 justify-center">
												{header.column.getIsPinned() !== "left" ? (
													<button
														type="button"
														className="ms-auto p-0.5 aspect-square text-slate-500 hover:text-blue-700 hover:bg-blue-100 rounded-full"
														onClick={() => {
															header.column.pin("left");
														}}
													>
														<DrawingPinIcon />
													</button>
												) : null}
												{header.column.getIsPinned() ? (
													<button
														type="button"
														className="ms-auto -rotate-45 p-0.5 aspect-square text-slate-500 hover:text-blue-700 hover:bg-blue-100 rounded-full"
														onClick={() => {
															header.column.pin(false);
														}}
													>
														<DrawingPinFilledIcon />
													</button>
												) : null}
											</div>
										)}
									{header.isPlaceholder ? null : (
										<>
											<span className="whitespace-nowrap capitalize">
												{flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
											</span>

											{header.column.getCanSort() && (
												<button
													type="button"
													role="button"
													className={
														header.column.getCanSort()
															? `p-0.5 aspect-square text-slate-500 hover:text-blue-700 hover:bg-blue-100 rounded-full ${
																	header.column.getIsSorted()
																		? "bg-blue-100"
																		: ""
																} `
															: "hidden"
													}
													onClick={header.column.getToggleSortingHandler()}
												>
													{!header.column.getIsSorted() && <CaretSortIcon />}
													{{
														asc: <CaretUpIcon className="text-blue-700" />,
														desc: <CaretDownIcon className="text-blue-700" />,
													}[header.column.getIsSorted() as string] ?? null}
												</button>
											)}
											{header.column.getCanFilter() ? (
												<FilterModal isFiltered={header.column.getIsFiltered()}>
													<Filter
														column={header.column}
														firstValue={instance
															.getPreFilteredRowModel()
															.flatRows[0]?.getValue(header.column.id)}
													/>
												</FilterModal>
											) : null}
										</>
									)}
									{!!powerMode &&
										!isLastColumn(headerGroup, headerIndex) &&
										header.column.getIsPinned() !== "left" && (
											<button
												type="button"
												className="ms-auto p-0.5 aspect-square text-slate-500 hover:text-blue-700 hover:bg-blue-100 rounded-full"
												onClick={() => {
													const newOrder = updateColumnOrder(
														header.column.id,
														true,
													);
													instance.setColumnOrder(newOrder);
												}}
											>
												<ChevronRightIcon />
											</button>
										)}
								</div>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{!!(isFetched && instance.getRowModel()?.rows?.length === 0) && (
					<tr>
						<td colSpan={90} className="p-4 text-center">
							<div className="flex flex-col items-start gap-4">
								<strong>
									<FormattedMessage id="NO_DATA_TO_DISPLAY" />
								</strong>
							</div>
						</td>
					</tr>
				)}
				{instance.getRowModel()?.rows.map((row) => (
					<React.Fragment key={row.id}>
						<tr>
							{row.getVisibleCells().map((cell) => (
								<td key={cell.id} className={styles.td}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					</React.Fragment>
				))}
			</tbody>
		</table>
	);
}

interface FooterProps<T> {
	instance: Table<T>;
	isFetching: boolean;
	setPagination: (PaginationState: PaginationState) => void;
	numberOfResults: number;
	isSearching?: boolean;
	isFetched?: boolean;
	hasCSVExport?: boolean;
}

function Footer<T>({
	instance,
	setPagination,
	isFetching,
	isSearching,
	hasCSVExport,
	isFetched,
	numberOfResults,
}: FooterProps<T>): JSX.Element {
	const intl = useIntl();
	const { CSVDownloader, Type } = useCSVDownloader();

	const handleExportRows = useCallback(
		(visibleRows: Row<T>[]): string[][] => {
			const headers =
				visibleRows[0]?.getVisibleCells()?.map(
					(visibleCell) =>
						intl.formatMessage({
							id: visibleCell.column.columnDef.id ?? "NO_NAME",
						}) ?? "",
				) ?? [];

			const dataRows = visibleRows.map((row) =>
				row.getVisibleCells().map((cell) => {
					const value = formatCSVExport<T>(cell, row);
					return value;
				}),
			);
			return [headers, ...dataRows];
		},
		[intl],
	);

	return (
		<div className="flex flex-wrap border-top border-1 p-2 pb-2 items-center gap-2 bg-white">
			<button
				type="button"
				className="border rounded p-1"
				onClick={() => instance.setPageIndex(0)}
				disabled={!instance.getCanPreviousPage()}
			>
				{"<<"}
			</button>
			<button
				type="button"
				className="border rounded p-1"
				onClick={() => instance.previousPage()}
				disabled={!instance.getCanPreviousPage()}
			>
				{"<"}
			</button>
			<button
				type="button"
				className="border rounded p-1"
				onClick={() => instance.nextPage()}
				disabled={!instance.getCanNextPage()}
			>
				{">"}
			</button>
			<button
				type="button"
				className="border rounded p-1"
				onClick={() => instance.setPageIndex(instance.getPageCount() - 1)}
				disabled={!instance.getCanNextPage()}
			>
				{">>"}
			</button>
			<span className="flex items-center gap-1">
				{isFetched && (
					<>
						{instance.getPageCount() ? (
							<>
								<div>
									<FormattedMessage id="PAGE" />
								</div>
								<strong>
									{`${
										instance.getState().pagination.pageIndex + 1
									} ${intl.formatMessage({
										id: "OF",
									})} ${instance.getPageCount()}`}
								</strong>
							</>
						) : (
							<strong>
								<FormattedMessage id="NO_PAGE_FOUND" />
							</strong>
						)}
					</>
				)}
			</span>
			<select
				value={instance.getState().pagination.pageSize}
				onChange={(e) => {
					instance.setPageSize(Number(e.target.value));
					setPagination({
						pageIndex: 0,
						pageSize: Number(e.target.value),
					});
				}}
			>
				{PAGINATION.map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						{intl.formatMessage({ id: "SHOW" })} {pageSize}
					</option>
				))}
			</select>
			{numberOfResults > 0 && (
				<span>
					{"("}
					<strong>{numberOfResults}</strong> <FormattedMessage id="RESULTS" />
					{")"}
				</span>
			)}
			{isFetching || isSearching ? (
				<span className={styles.loading}>
					<FormattedMessage id="LOADING" />
				</span>
			) : (
				<span data-testid="download-csv">
					{hasCSVExport && (
						<CSVDownloader
							type={Type.Link}
							filename="filename"
							className={styles.downloadLink}
							bom
							config={{
								delimiter: ";",
								header: true,
							}}
							data={handleExportRows(instance.getRowModel()?.rows)}
						>
							<FormattedMessage id="EXPORT_VISIBLE_ROWS" />
						</CSVDownloader>
					)}
				</span>
			)}
		</div>
	);
}

export { Footer, SidePanel, TableBody, TableHeader };
