import { ProcessStreamSummary } from "@metronome/features/ProcessStream/Summary";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import { useNavigate } from "@tanstack/react-router";
import type React from "react";
import { FormattedMessage } from "react-intl";
import { useBatches } from "@metronome/api/useBatches";
import { format } from "date-fns/format";
import { isToday, isTomorrow, isYesterday, parseISO } from "date-fns";
import { Badge } from "@metronome/components/ui/badge";
import clsx from "clsx";
import { useEffect } from "react";
import { Route } from "@metronome/routes/$workspaceId/stream/$streamId/routine";

type Props = {
	dateString: string;
};
const FormatDateForBatch: React.FC<Props> = ({ dateString }) => {
	const dateIsToday = isToday(dateString);
	if (dateIsToday) {
		return <FormattedMessage id="TODAY" />;
	}

	const dateIsTomorrow = isTomorrow(dateString);
	if (dateIsTomorrow) {
		return <FormattedMessage id="TOMORROW" />;
	}

	const dateIsYesterday = isYesterday(dateString);
	if (dateIsYesterday) {
		return <FormattedMessage id="YESTERDAY" />;
	}

	return dateString;
};

type BatchesMenuProps = {
	streamId: string;
};
const BatchesMenu: React.FC<BatchesMenuProps> = ({ streamId }) => {
	const navigate = useNavigate();
	const { selectedBatch } = Route.useSearch();
	const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
	const from = format(todayMidnight, "yyyy-MM-dd");
	const { data: batches } = useBatches(streamId, from);
	const indexCurrentBatch = batches?.findIndex(
		(b) => parseISO(b.referenceDate) >= todayMidnight,
	);
	useEffect(() => {
		if (
			!selectedBatch &&
			indexCurrentBatch &&
			indexCurrentBatch >= 0 &&
			batches?.length
		) {
			navigate({
				search: {
					selectedBatch: batches[indexCurrentBatch]?.id,
				},
			});
		}
	}, [selectedBatch, indexCurrentBatch, batches, navigate]);
	if (batches)
		return (
			<ul className="flex flex-row items-center gap-4">
				{batches?.map((b) => {
					return (
						<li
							className={clsx({
								"rounded font-semibold border ": true,
								"bg-white text-primary border-primary border-solid":
									selectedBatch === b.id,
								"border-dashed text-slate-500 border-slate-400":
									selectedBatch !== b.id,
							})}
							key={b.id}
						>
							<button
								className="px-2 py-1.5 "
								type="button"
								onClick={() =>
									navigate({
										search: {
											selectedBatch: selectedBatch === b.id ? undefined : b.id,
										},
									})
								}
							>
								<FormatDateForBatch dateString={b.referenceDate} />
								<Badge
									variant={selectedBatch === b.id ? "default" : "outline"}
									className={
										selectedBatch === b.id
											? "text-xs px-1 ms-2 text-white border-primary"
											: "text-xs px-1 ms-2 text-slate-500 bg-white"
									}
								>
									{b.state}
								</Badge>
							</button>
						</li>
					);
				})}
			</ul>
		);
	return null;
};

type ProcessStreamTemplateProps = {
	processStream: IProcessStream;
	isLoading: boolean;
	children: React.ReactNode;
};

export const ProcessStreamTemplate: React.FC<ProcessStreamTemplateProps> = ({
	processStream,
	children,
	isLoading,
}) => {
	const isRecurringStream = processStream.type === "routine";

	return (
		<>
			<ProcessStreamSummary
				isLoading={isLoading}
				processStream={processStream}
			/>
			<div>
				{isRecurringStream ? <BatchesMenu streamId={processStream.id} /> : null}
				<div>{children}</div>
			</div>
		</>
	);
};
