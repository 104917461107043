import { z } from "zod";
import { IModelReference } from "./ModelReference";
import { IProcessTemplateVersion } from "./ProcessTemplate";
import { ThirdApps } from "./ThirdApp";
import { ETimeliness } from "./Timeliness";

export const IRoleRequirement = z.object({
	roleName: z.string(),
	roleId: z.string(),
});
export type IRoleRequirement = z.infer<typeof IRoleRequirement>;

export const IFilteringValueLabel = IModelReference.extend({
	operation: z.string(),
});
export type IFilteringValueLabel = z.infer<typeof IFilteringValueLabel>;

export const IDispatch = z.object({
    labels: z.array(IFilteringValueLabel),
    level: IModelReference.optional(),
    node: IModelReference,
});

export type IDispatch = z.infer<typeof IDispatch>;

export const IProcessStream = z.object({
	id: z.string(),
	name: z.string(),
    type: z.enum(["routine", "onDemand", "clustered"]).optional(),
    isActive: z.boolean(),
	organization: IModelReference,
	currentProcessTemplateVersion: IProcessTemplateVersion.optional(),
	recurrence: z.string(),
	dispatch: IDispatch.optional(),
	closeOnCompletion: z.boolean(),
	autoClose: z.number(),
	category: IModelReference,
	pictureUri: z.string().optional(),
	origins: z.array(ThirdApps).optional(),
	timeZone: z.string(),
});
export type IProcessStream = z.infer<typeof IProcessStream>;
