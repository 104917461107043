import type { FC } from "react";
import { FormattedMessage } from "react-intl";

export const SchedulerError: FC = () => {
	return (
		<div className="min-h-96 flex flex-col items-center justify-center">
			<h2 className="font-bold text-primary text-lg">
				<FormattedMessage id="ERROR.TITLE.TIMELINE" />
			</h2>
			<p>
				<FormattedMessage id="ERROR.DESC.TIMELINE" />
			</p>
		</div>
	);
};
