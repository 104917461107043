import { useCanvas } from "@metronome/hooks/useCanvas";
import type { ISchedule } from "@metronome/types/StepInstance";
import {
	getMinutes,
	hoursToMinutes,
	interval,
	intervalToDuration,
} from "date-fns";
import * as duration from "duration-fns";

type StepScheduleProps = {
	schedule: ISchedule;
};

const MINUTES_IN_DAY = 1440;

const getStepDurationInMinutes = (schedule: ISchedule) => {
	const scheduleInterval = interval(schedule.lowerBand, schedule.upperBand);
	const scheduleDuration = intervalToDuration(scheduleInterval);
	const durationParsed = duration.parse(scheduleDuration);
	return duration.toMinutes(durationParsed);
};

export const getDurationOffset = (targetStart: string) => {
	const noTmz = targetStart.slice(0, 19);
	const newDate = new Date(noTmz);
	const offsetMinutes = getMinutes(newDate);
	const hours = newDate.getHours();
	const offsetHoursToMinutes = hoursToMinutes(hours);
	return offsetHoursToMinutes + offsetMinutes;
};

export const StepSchedule: React.FC<StepScheduleProps> = ({ schedule }) => {
	let durationToMinutes: number | null = null;
	let offset = 0;

	try {
		durationToMinutes = getStepDurationInMinutes(schedule);
		offset = getDurationOffset(schedule.lowerBand);
	} catch (error) {
		console.log("error:", error);
	}

	let width = 0;
	const height = 22;
	let x = 0;
	const y = 0;

	if (durationToMinutes === 0) {
		width = 100;
	} else if (durationToMinutes) {
		width = Math.round((durationToMinutes / MINUTES_IN_DAY) * 100);
	}

	if (offset) {
		x = Math.round((offset / MINUTES_IN_DAY) * 100);
	}

	const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
		ctx.fillStyle = "hsla(186 99.25% 98.44%)";
		ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

		ctx.fillStyle = "#085e68";
		ctx.fillRect(x, y, width, height);
	};
	const canvasRef = useCanvas(draw);

	return (
		<canvas
			width={100}
			height={22}
			test-id="step-schedule"
			aria-label="Step duration in a day"
			ref={canvasRef}
		/>
	);
};
