import { selfQueryOptions } from "@metronome/api/useSelf";
import { Navigate, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
	loader: async ({ context }) =>
		context.queryClient.ensureQueryData(selfQueryOptions()),
	component: () => {
		const data = Route.useLoaderData();
		if (data?.defaultWorkspaceId)
			return (
				<Navigate
					to="/$workspaceId/your-work/steps"
					params={{ workspaceId: data?.defaultWorkspaceId }}
				/>
			);
		return null;
	},
});
