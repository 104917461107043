import { format } from "date-fns/format";
import { FormattedMessage, useIntl } from "react-intl";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
	keepPreviousData,
	useQueries,
	type QueriesResults,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";
import { EResolution, type ResolutionParam } from "@metronome/types/Resolution";
import {
	IFilters,
	IStepInstance,
	type IStepInstanceView,
	type IStepRequirements,
} from "@metronome/types/StepInstance";

import type { IBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { AxiosError, AxiosResponse } from "axios";

import { z } from "zod";
import type { ExpectedErrorResponseType } from "./api";
import api, { HTTPMethod, apiGet, apiPut } from "./api";
import { processInstanceKeys } from "./useProcessInstance";
import axios from "axios";
import type { IStage } from "@metronome/types/Gate";

type Params = {
	[key: string]: string | string[] | number | undefined;
};

export const stepInstanceKeys = {
	single: (workspaceId: string, stepInstanceId: string) =>
		[workspaceId, "single-step-instance", stepInstanceId] as const,
	all: (workspaceId: string) => [workspaceId, "step-instances"] as const,
	logEvents: (workspaceId: string, params: Params) =>
		[workspaceId, "step-instances-log-events", params] as const,
	search: (workspaceId: string, params: Params) =>
		[...stepInstanceKeys.all(workspaceId), params] as const,
	filtered: (workspaceId: string, params?: Params) =>
		[...stepInstanceKeys.all(workspaceId), params] as const,
	requirements: (workspaceId: string, stepInstanceId: string) =>
		[
			...stepInstanceKeys.single(workspaceId, stepInstanceId),
			"requirements",
		] as const,
	views: (workspaceId: string, stepInstanceId: string) =>
		[...stepInstanceKeys.single(workspaceId, stepInstanceId), "views"] as const,
};
interface BaseParams {
	workspaceId: string;
	stepInstanceId: string;
}

interface UpdateAgendaArgs extends BaseParams {
	agendaId: string;
	completed: boolean;
}
const updateAgendaElement = async ({
	workspaceId,
	stepInstanceId,
	agendaId,
	completed,
}: UpdateAgendaArgs): Promise<IStepInstance> => {
	const res = await apiPut<IStepInstance>(
		`ws/${workspaceId}/step-instances/${stepInstanceId}/agendas/${agendaId}`,
		{
			completed,
		},
	);
	return res.data as IStepInstance;
};

interface UpdateResolutionArgs {
	workspaceId: string;
	stepInstanceId: string;
	processInstanceId: string;
	resolution: EResolution;
	isForced: boolean | undefined;
}
const updateResolution = async ({
	workspaceId,
	stepInstanceId,
	resolution,
	isForced,
}: UpdateResolutionArgs): Promise<IStepInstance> => {
	const res = await apiPut(
		`ws/${workspaceId}/step-instances/${stepInstanceId}/resolution/`,
		{
			resolution,
			isForced,
		},
	);
	return res.data as IStepInstance;
};

export type ETypeOfSchedule = "deadline" | "resolution-date" | "target-date";
type Payload = { typeOfSchedule: ETypeOfSchedule; date: Date };
export function useUpdateStepInstanceSchedule(
	stepInstanceId: string,
	processInstanceId: string,
): UseMutationResult<
	AxiosResponse<IStepInstance> | { data: undefined },
	Error,
	Payload
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateDueDate: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ typeOfSchedule, date }) =>
			apiPut<IStepInstance>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/${typeOfSchedule}`,
				{
					dateTime: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
				},
			),

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.all(workspaceId),
			});
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.single(workspaceId, stepInstanceId),
			});
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
			});
			toast.success(<FormattedMessage id="SUCCESS" />);
		},
	});
}

export function useUpdateResolution(): UseMutationResult<
	IStepInstance,
	AxiosError<ExpectedErrorResponseType>,
	ResolutionParam
> {
	const intl = useIntl();
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateResolution: workspaceId is not defined");
	}

	return useMutation({
		mutationFn: ({
			resolution,
			isForced,
			stepInstanceId,
			processInstanceId,
		}: ResolutionParam) =>
			updateResolution({
				workspaceId,
				stepInstanceId,
				resolution,
				isForced,
				processInstanceId,
			}),

		onError: (e) => {
			if (axios.isAxiosError(e)) {
				if (e.response?.status !== 400) {
					toast.error(`Error: ${e.message}`);
				}
			}
		},

		onSuccess: (data, { processInstanceId }) => {
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.all(workspaceId),
			});
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.single(workspaceId, data.id),
			});
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.single(workspaceId, processInstanceId),
			});
			if (data?.resolution === EResolution.enum.done) {
				toast.success(
					<div className="flex flex-col">
						{intl.formatMessage({ id: "RESOLUTION_DONE_SUCCESS" })}
					</div>,
				);
			}
		},
	});
}

export function useUpdateAgenda(stepInstanceId: string): UseMutationResult<
	IStepInstance,
	Error,
	{
		agendaId: string;
		completed: boolean;
	}
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();

	if (!workspaceId) {
		throw new Error("useUpdateAgenda: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({
			agendaId,
			completed,
		}: { agendaId: string; completed: boolean }) =>
			updateAgendaElement({ workspaceId, stepInstanceId, agendaId, completed }),

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.single(workspaceId, stepInstanceId),
			});
		},
	});
}

const RequestSchema = z.object({
	organizationId: z.string().optional(),
});

const fetchStepInstancesFilters = api<
	z.infer<typeof RequestSchema>,
	z.infer<typeof IFilters>
>({
	method: HTTPMethod.enum.GET,
	responseSchema: IFilters,
});

export function useGetStepInstancesFilters(): UseQueryResult<
	IFilters | undefined,
	Error
> {
	const workspaceId = useWorkspaceId();

	const { activeOrganization: organizationId } = useOrganizationData();
	if (!workspaceId) {
		throw new Error("useGetStepInstancesFilters: workspaceId is not defined");
	}
	return useQuery({
		queryKey: [workspaceId, organizationId, "stepInstanceFilters"],

		queryFn: () =>
			fetchStepInstancesFilters(`ws/${workspaceId}/step-instances/filters`, {
				organizationId,
			}),

		staleTime: Number.POSITIVE_INFINITY,
		gcTime: Number.POSITIVE_INFINITY,
	});
}

export function useFilteredStepInstances(
	params: Params,
	enabled: boolean,
): UseQueryResult<IPaginatedResults<IStepInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization: organizationId } = useOrganizationData();
	if (!workspaceId) {
		throw new Error("useFilteredStepInstances: workspaceId is not defined");
	}
	return useQuery({
		queryKey: stepInstanceKeys.filtered(workspaceId, {
			...params,
			organizationId,
		}),

		queryFn: () =>
			apiGet<IPaginatedResults<IStepInstance>>(
				`ws/${workspaceId}/step-instances`,
				{ params },
			),

		placeholderData: keepPreviousData,
		enabled,
	});
}

export function useStepInstancesForLogEvents(
	params: Params,
	enabled: boolean,
): UseQueryResult<IPaginatedResults<IStepInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization: organizationId } = useOrganizationData();
	if (!workspaceId) {
		throw new Error("useStepInstancesForLogEvents: workspaceId is not defined");
	}
	return useQuery({
		queryKey: stepInstanceKeys.logEvents(workspaceId, {
			...params,
			organizationId,
		}),

		queryFn: () =>
			apiGet<IPaginatedResults<IStepInstance>>(
				`ws/${workspaceId}/step-instances`,
				{ params },
			),

		enabled,
	});
}

export function useStepInstanceRequirements(
	stepInstanceId: string,
	resolution?: EResolution,
): UseQueryResult<IStepRequirements[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useStepInstances: workspaceId is not defined");
	}
	return useQuery({
		queryKey: stepInstanceKeys.requirements(workspaceId, stepInstanceId),

		queryFn: () =>
			apiGet<IStepRequirements[]>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/requirements`,
			),

		enabled:
			resolution !== EResolution.enum.done &&
			resolution !== EResolution.enum.cancelled,
	});
}

const fetchStepInstance = api<void, z.infer<typeof IStepInstance>>({
	method: HTTPMethod.enum.GET,
	responseSchema: IStepInstance,
});

export default function useStepInstance(
	stepInstanceId: string,
): UseQueryResult<
	IStepInstance | undefined,
	AxiosError<ExpectedErrorResponseType>
> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useStepInstances: workspaceId is not defined");
	}
	return useQuery({
		queryKey: stepInstanceKeys.single(workspaceId, stepInstanceId),

		queryFn: () =>
			fetchStepInstance(`ws/${workspaceId}/step-instances/${stepInstanceId}`),

		placeholderData: keepPreviousData,
	});
}

export function useStepInstanceViews(
	stepInstanceId: string,
): UseQueryResult<IStepInstanceView[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useStepInstances: workspaceId is not defined");
	}
	return useQuery({
		queryKey: stepInstanceKeys.views(workspaceId, stepInstanceId),

		queryFn: async () => {
			const res = await apiPut<IStepInstanceView[]>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/views`,
				{},
			);
			return res.data as IStepInstanceView[];
		},
	});
}

const combineData = (results: QueriesResults<IStage[]>) => {
	return {
		data: results.map((result) => result.data) as Array<IStage[]>,
		pending: results.some((result) => result.isPending),
	};
};

function fetchStepInstanceFromProcess(id: string, workspaceId: string) {
	return apiGet<IStage[]>(`ws/${workspaceId}/process-instances/${id}/steps`);
}

export function useStepInstancesFromProcesses(processIds: string[]) {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error(
			"useStepInstancesFromProcesses: workspaceId is not defined",
		);
	}
	return useQueries({
		queries:
			processIds?.length > 0
				? processIds.map((id) => ({
						queryKey: [workspaceId, "process", id, "stepInstances"], // to be replace by queryKey factory function
						queryFn: () => fetchStepInstanceFromProcess(id, workspaceId),
					}))
				: [],
		combine: combineData,
	});
}

type UpdateNodeNameType = {
	workspaceId: string;
	nodeId: string;
	name: string;
};

const updateNodeName = async ({
	workspaceId,
	nodeId,
	name,
}: UpdateNodeNameType): Promise<IBusinessDimensionNode> => {
	const res = await apiPut(`ws/${workspaceId}/nodes`, {
		id: nodeId,
		name,
	});
	return res.data as IBusinessDimensionNode;
};

export function useUpdateNodeName(
	stepInstanceId: string,
): UseMutationResult<
	Partial<IStepInstance>,
	AxiosError<ExpectedErrorResponseType>,
	{ nodeId: string; name: string }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateNodeName: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ nodeId, name }: { nodeId: string; name: string }) =>
			updateNodeName({ workspaceId, nodeId, name }),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.single(workspaceId, stepInstanceId),
			});
		},
	});
}
