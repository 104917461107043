import { EResolution } from "@metronome/types/Resolution";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { UserStepsConfigLoader } from "../../../components/DataGrid/UserConfigLoader";
import useSaveTableViews from "@metronome/api/useSaveTableViews";
import useUserPreferences from "@metronome/api/useUserPreference";
import useWorkspaceViews from "@metronome/api/useWorkspaceViews";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import * as v from "valibot";
import LoadingMetronome from "@metronome/components/LoadingMetronome";

const yourWorkSearchParams = v.object({
	search: v.optional(v.string()),
	stepId: v.optional(v.string()),
	processIds: v.optional(v.array(v.string())),
	labelIds: v.optional(v.array(v.string())),
	stepTemplateIds: v.optional(v.array(v.string())),
	nodeIds: v.optional(v.array(v.string())),
	nodeTypeIds: v.optional(v.array(v.string())),
	page: v.optional(v.number()),
	pageSize: v.optional(v.number()),
	sortOrder: v.optional(v.union([v.literal("asc"), v.literal("desc")])),
	sortBy: v.optional(v.string()),
	resolution: v.optional(v.array(v.string())),
	deadlineFrom: v.optional(v.string()),
	deadlineTo: v.optional(v.string()),
	completedFrom: v.optional(v.string()),
	completedTo: v.optional(v.string()),
});

export const Route = createFileRoute("/$workspaceId/your-work/steps")({
	validateSearch: (searchParams) => v.parse(yourWorkSearchParams, searchParams),
	component: () => <YourWorkSteps />,
});

const resolutions = [
	EResolution.enum.inProgress,
	EResolution.enum.notStarted,
	EResolution.enum.onHold,
];

const processStates = ["active" as const];
const PREFERENCE_KEY_YOUR_WORK = "metronome.yourwork.stepinstances";

const YourWorkSteps = () => {
	useSetCurrentOrganization(undefined);

	const { data: savedViews } = useSaveTableViews(PREFERENCE_KEY_YOUR_WORK);
	const { data: workspaceViews, isLoading: isWorkspaceViewLoading } =
		useWorkspaceViews(PREFERENCE_KEY_YOUR_WORK);
	const { data: preferencesSteps, isLoading: isUserPrefLoading } =
		useUserPreferences(PREFERENCE_KEY_YOUR_WORK);

	if (isUserPrefLoading || isWorkspaceViewLoading) {
		return <LoadingMetronome />;
	}

	return (
		<ActiveViewProvider preferences={preferencesSteps}>
			<UserStepsConfigLoader
				preferenceKey={PREFERENCE_KEY_YOUR_WORK}
				resolutions={resolutions}
				processStates={processStates}
				savedViews={savedViews}
				workspaceViews={workspaceViews}
				preferences={preferencesSteps}
				context="your-work"
			/>
			<Outlet />
		</ActiveViewProvider>
	);
};
