import useSelf from "@metronome/api/useSelf";
import Avatar from "@metronome/components/Avatar";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { getInitials } from "@metronome/utils/formatText";
import { getResponsibleUser, getUserInfoUri } from "@metronome/utils/user";

export const ResponsibleUser = ({
	responsibleAssignees,
}: {
	responsibleAssignees: IStepInstance["assignments"]["responsibleAssignees"];
}): JSX.Element => {
	const { data: user } = useSelf();
	const assignee = getResponsibleUser(responsibleAssignees, user);
	return (
		<div className="flex items-center gap-2">
			<Avatar
				src={getUserInfoUri(assignee)}
				alt={getInitials(assignee?.firstName, assignee?.lastName)}
			/>
			<div>
				<p className="mb-0 font-bold text-nowrap">
					{assignee?.firstName || ""} {assignee?.lastName}
				</p>
			</div>
		</div>
	);
};
