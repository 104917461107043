import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const ActivitiesSearchParams = z.object({
	search: z.string().optional(),
	stepId: z.string().optional(), // this optional step id is used for modal view
	page: z.number().optional().catch(1),
	pageSize: z.number().optional().catch(25),
	sortOrder: z.union([z.literal("asc"), z.literal("desc")]).optional(),
	sortBy: z.string().optional(),
	startFrom: z.string().optional(),
	startTo: z.string().optional(),
	resolution: z.array(z.string()).optional(),
	timeliness: z.string().optional(),
	responsibleIds: z.array(z.string()).optional(),
});

export const Route = createFileRoute("/$workspaceId/activities")({
	validateSearch: (searchParams) => ActivitiesSearchParams.parse(searchParams),
});
