import { Button } from "@metronome/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { NodeReference } from "./Modals/ReferenceModal";
import { FormattedMessage } from "react-intl";
import { Pencil1Icon } from "@radix-ui/react-icons";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { useState } from "react";
import type { INodeReferenceSpec } from "@metronome/types/BusinessDimension";

type ConnectedNodesProps = {
	stepInstance: IStepInstance;
	referenceSpecs: INodeReferenceSpec[];
};
export const ConnectedNodes: React.FC<ConnectedNodesProps> = ({
	stepInstance,
	referenceSpecs,
}) => {
	const [open, setOpen] = useState(false);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild={stepInstance.nodeReferences.length === 0}>
				{stepInstance.nodeReferences.length ? (
					<Pencil1Icon />
				) : (
					<Button variant="outline">
						<FormattedMessage id="ADD_RESOURCES" />
					</Button>
				)}
			</DialogTrigger>
			<DialogContent>
				<NodeReference
					stepInstanceId={stepInstance.id}
					node={stepInstance.businessDimension}
					nodeReferences={stepInstance.nodeReferences}
					referenceSpecs={referenceSpecs}
					onSuccess={() => setOpen(false)}
				/>
			</DialogContent>
		</Dialog>
	);
};
