import type { IStage } from "@metronome/types/Gate";
import { EResolution } from "@metronome/types/Resolution";
import type { IStep, IStepInstanceLight } from "@metronome/types/Step";

export function isNotDoneOrCancel(resolution?: EResolution): boolean {
	if (!resolution) return false;
	return (
		resolution !== EResolution.enum.cancelled &&
		resolution !== EResolution.enum.done
	);
}

const getFirstOpenGate = (gates: IStage[]): IStage | undefined =>
	gates?.find((gate) =>
		gate.steps.find((step) =>
			step.stepInstances.find((instance) =>
				isNotDoneOrCancel(instance.resolution),
			),
		),
	);
const getFirstOpenStepGroup = (firstOpenGate: IStage): IStep | undefined =>
	firstOpenGate?.steps?.find((step) =>
		step.stepInstances.find((instance) =>
			isNotDoneOrCancel(instance.resolution),
		),
	);
const getFirstOpenStepInstances = (
	stepInstances: IStepInstanceLight[],
): IStepInstanceLight | undefined =>
	stepInstances.find((stepInstance) =>
		isNotDoneOrCancel(stepInstance.resolution),
	);

const getFirstStepInstance = (gates: IStage[]) =>
	gates
		?.find((gate) => gate.steps?.length)
		?.steps.find((step) => step.stepInstances?.length)?.stepInstances?.[0]
		.id as string;

export const getFirstNotDoneStepInstance = (
	gates?: IStage[],
): string | undefined => {
	if (!gates || !gates.length) {
		return undefined;
	}
	const firstStepIdBackup = gates[0].steps[0].stepInstances[0]?.id;
	const firstOpenGate = getFirstOpenGate(gates);
	if (!firstOpenGate) {
		return firstStepIdBackup;
	}
	const firstOpenStepGroup = getFirstOpenStepGroup(firstOpenGate);
	if (!firstOpenStepGroup || !firstOpenStepGroup.stepInstances) {
		return firstStepIdBackup;
	}
	const firstOpenStepInstances = getFirstOpenStepInstances(
		firstOpenStepGroup.stepInstances,
	);
	return firstOpenStepInstances?.id ?? firstStepIdBackup;
};
