import type React from "react";
import { useIntl } from "react-intl";

import { useOrganizationData } from "@metronome/context/OrganizationData";
import { useSelfUpdate } from "@metronome/api/useSelf";
import { UpdateIcon } from "@radix-ui/react-icons";

export const SwitchOrganizationNative: React.FC = () => {
	const intl = useIntl();
	const { mutate, isPending } = useSelfUpdate();
	const { organizations, activeOrganization } = useOrganizationData();

	if (organizations?.length === 1) return null;

	return (
		<div className="relative">
			<select
				value={activeOrganization ?? organizations?.[0]?.id}
				onChange={(event) => {
					mutate({ defaultOrganizationId: event.target.value });
				}}
			>
				<option>
					{organizations?.find((orga) => orga.id === activeOrganization)
						?.name ?? intl.formatMessage({ id: "YOUR_WORK.ALL_ORGANIZATIONS" })}
				</option>
				{organizations?.map((organization) => (
					<option key={organization.id} value={organization.id ?? ""}>
						{organization.name ?? ""}
					</option>
				))}
			</select>
			{isPending && <UpdateIcon className="ms-2 inline-block animate-spin" />}
		</div>
	);
};
