import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { ProcessStreamPage } from "./-streamPage";
import * as v from "valibot";

const RoutineParams = v.object({
	selectedBatch: v.optional(v.string()),
});

export const Route = createFileRoute("/$workspaceId/stream/$streamId/routine")({
	validateSearch: (searchParams) => v.parse(RoutineParams, searchParams),
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);
		return;
	},
	component: () => {
		const { streamId, workspaceId } = Route.useParams();
		const { data: processStream } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);

		return <ProcessStreamPage processStream={processStream} />;
	},
});
