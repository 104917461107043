import { Avatar } from "@metronome/components/Avatar";
import { UserPopover } from "@metronome/components/UserPopover";
import type { IParty } from "@metronome/types/Party";
import { getInitials } from "@metronome/utils/formatText";
import { getUserInfoUri, useName } from "@metronome/utils/user";
import type React from "react";
import styles from "./avatarsList.module.scss";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import clsx from "clsx";
import { isDemo } from "@metronome/utils/env";

const getUserBorderColor = (i: number) => {
	if (!isDemo) {
		return "border-green-700";
	}
	if (i === 0) {
		return "border-green-700";
	}
	if (i === 1) {
		return "border-green-600";
	}
	return "border-yellow-500";
};

const UserComponent = ({
	user,
	onSelect,
}: { user: IParty; onSelect: (userId: string) => void }): JSX.Element => (
	<button
		type="button"
		onClick={() => onSelect(user.id)}
		className="flex flex-row py-2 items-center"
	>
		<Avatar
			size={42}
			src={getUserInfoUri(user)}
			alt={getInitials(user?.firstName, user?.lastName)}
		/>
		<div
			className={styles.user_details}
			key={user.id + user.roles?.map((role) => role.id)?.join("-")}
		>
			<strong>{useName(user)}</strong>
			<span className="text-base truncate">{user.email}</span>
		</div>
	</button>
);

const DisplayAllUsers = ({
	numberOfUsersLeft,
	usersLeft,
	onSelect,
}: {
	numberOfUsersLeft: number;
	usersLeft: IParty[];
	onSelect: (userId: string) => void;
}): JSX.Element => {
	return (
		<>
			<Popover>
				<PopoverTrigger className={styles.display_all_users}>
					{`+${numberOfUsersLeft}`}
				</PopoverTrigger>
				<PopoverContent>
					{usersLeft.map((user) => (
						<UserComponent
							key={user.id + user.roles?.map((r) => r.id)?.join("-")}
							user={user}
							onSelect={onSelect}
						/>
					))}
				</PopoverContent>
			</Popover>
		</>
	);
};

type Props = {
	users: IParty[];
	numberToDisplay?: number;
	wideSpacing?: boolean;
	children?: React.ReactNode;
	onSelect?: (userId: string) => void;
	selectedUser?: string;
	size?: number;
};

export const AvatarsList: React.FC<Props> = ({
	users,
	numberToDisplay,
	wideSpacing = true,
	children,
	onSelect,
	selectedUser,
	size = 32,
}) => {
	if (!users?.length) {
		return <div />;
	}

	const uniqueUsers = [...new Map(users.map((v) => [v.id, v])).values()];

	const maxUsersToDisplay = numberToDisplay || users.length;
	const usersToDisplay = uniqueUsers.slice(0, maxUsersToDisplay);

	const usersLeft = users.slice(numberToDisplay, users.length);

	const clickableUserImages = usersToDisplay.map((user, index) => {
		if (onSelect) {
			const initials = getInitials(user?.firstName, user?.lastName);
			const borderColor = getUserBorderColor(index);
			return (
				<button
					className={clsx({
						"opacity-50 !border-transparent":
							!!selectedUser && selectedUser !== user.id,
						[`rounded-full border border-2 ${borderColor}`]: true,
					})}
					key={user.id}
					type="button"
					onClick={() => onSelect(user.id)}
				>
					<Avatar size={size} src={getUserInfoUri(user)} alt={initials} />
				</button>
			);
		}
		return (
			<div
				// className={clsx({ "opacity-50": selectedUser === user.id })}
				key={user.id + user.roles?.map((r) => r.id)?.join("-")}
			>
				<UserPopover key={`user-${user ? user.id : index}`} user={user} />
			</div>
		);
	});

	return (
		<div className={"flex gap-1"}>
			{clickableUserImages}
			{users.length > maxUsersToDisplay && (
				<DisplayAllUsers
					usersLeft={usersLeft}
					numberOfUsersLeft={users.length - maxUsersToDisplay}
					onSelect={(userId: string) => onSelect?.(userId)}
				/>
			)}
			{children}
		</div>
	);
};
