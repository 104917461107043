import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import { EXPANDED_SIZE } from "@metronome/constants/planbyTheme";
import { EStepInstanceType } from "@metronome/types/StepInstance";
import { ETimeliness } from "@metronome/types/Timeliness";
import {
	type ProgramItem,
	useProgram,
	ProgramBox,
	ProgramContent,
	ProgramFlex,
	ProgramStack,
	ProgramTitle,
	ProgramText,
} from "@nessprim/planby-pro";
import { Link } from "@tanstack/react-router";
import { isSameDay } from "date-fns";

const CustomItemSchedule = ({
	since,
	till,
	formatTime,
	set12HoursTimeFormat,
}) => {
	const isScheduleSameDay = isSameDay(since, till);
	const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
	const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
	if (isScheduleSameDay) {
		return <span>{sinceTime}</span>;
	}
	return (
		<span>
			{sinceTime} - {tillTime}
		</span>
	);
};

export const CustomItem = ({ program, ...rest }: ProgramItem) => {
	const { isLive, styles, formatTime, set12HoursTimeFormat } = useProgram({
		program,
		...rest,
	});

	const { data } = program;
	const {
		title,
		since,
		till,
		type,
		id,
		channelUuid,
		workspaceId,
		doneStepInstances,
		totalStepInstances,
		timeliness,
	} = data;

	const timelinessStyle =
		timeliness === ETimeliness.enum.late
			? "from-red-100 to-red-100 !border-red-500"
			: "from-green-100 to-green-100 !border-green-500";

	if (type === EStepInstanceType.enum.milestone) {
		return (
			<ProgramBox
				style={styles.position}
				width={styles.width}
				className="translate-x-1/2 !bg-transparent relative flex flex-col text-center items-center justify-center !z-50"
			>
				<div className="scale-150">
					<StepInstanceIcon type={type} />
				</div>
				<span>{title}</span>
			</ProgramBox>
		);
	}

	return (
		<ProgramBox
			width={styles.width}
			style={styles.position}
			className={`!z-[8] overflow-auto ${
				styles.width < 200 ? "hover:!w-[280px]" : ""
			} transition-all duration-300 ease-in-out`}
		>
			<Link
				to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
				params={{
					workspaceId,
					processId: channelUuid,
					stepId: id,
				}}
			>
				<ProgramContent
					className={`!bg-gradient-to-r ${timelinessStyle} !border-2`}
					width={styles.width}
					isLive={isLive}
				>
					<ProgramFlex>
						{/* {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />} */}
						<ProgramStack>
							<ProgramTitle>
								<span className="pe-2 text-slate-900">
									<StepInstanceIcon type={type} />
								</span>
								<span className="text-slate-900">{title}</span>
							</ProgramTitle>
							{styles.position.height >= EXPANDED_SIZE && (
								<ProgramText className="text-slate-900">
									<CustomItemSchedule
										since={since}
										till={till}
										formatTime={formatTime}
										set12HoursTimeFormat={set12HoursTimeFormat}
									/>
									{totalStepInstances > 1 && (
										<span className="ms-2 px-1 bg-slate-200 rounded">{`${doneStepInstances}/${totalStepInstances}`}</span>
									)}
								</ProgramText>
							)}
						</ProgramStack>
					</ProgramFlex>
				</ProgramContent>
			</Link>
		</ProgramBox>
	);
};
