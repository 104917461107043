import * as duration from "duration-fns";
import type { Mode } from "@nessprim/planby-pro/dist/Epg/helpers";

export const getDefaultTimeRange = (
	first: string,
	last: string,
): Mode["type"] => {
	const durationBetween = duration.between(first, last);
	const normalizedDuration = duration.normalize(durationBetween);
	const numberofDays = duration.toDays(normalizedDuration);

	if (numberofDays > 1) {
		return "week";
	}
	return "day";
};

export const getDayWidth = (
	mode: Mode["type"],
	numberOfDays: number,
): number => {
	if (mode === "day" && numberOfDays >= 1) return 1800 * numberOfDays;
	if (mode === "week") return 14400;
	if (mode === "month") return 1800;
	return 1800;
};

export const getAvailableRange = (
	defaultRange: Mode["type"],
): Mode["type"][] => {
	if (defaultRange === "day") return ["day"];
	if (defaultRange === "week") return ["day", "week"];
	return ["day", "week", "month"];
};

export const durationInDays = (dateRight: string, dateLeft: string) => {
	const durationRaw = duration.between(dateRight, dateLeft);
	return duration.toDays(durationRaw);
};
