import useProcessCategories from "@metronome/api/useProcessCategories";
import useSelf from "@metronome/api/useSelf";
import Avatar from "@metronome/components/Avatar";
import { ComboBoxMultiSelect } from "@metronome/components/ComboBoxMultiSelect";
import { KPIYourWork } from "@metronome/components/KPIYourWork";
import { Button } from "@metronome/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { Tabs, TabsList, TabsTrigger } from "@metronome/components/ui/tabs";
import { useProcessCategoriesData } from "@metronome/context/ProcessCategoriesData";
import { LogEvent } from "@metronome/features/LogEvent";
import { RACIFilter } from "@metronome/features/RACIFilter/RACIFilter";
import type { ActiveView } from "@metronome/types/UserPreference";
import { getInitials } from "@metronome/utils/formatText";
import { getUserUri } from "@metronome/utils/user";
import { GearIcon } from "@radix-ui/react-icons";
import { useChildMatches, useNavigate } from "@tanstack/react-router";
import type React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type YourWorkTemplateProps = {
	activeView?: ActiveView;
};

type ActiveTab = "steps" | "processes";

export const YourWorkTemplate: React.FC<YourWorkTemplateProps> = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const { data: user } = useSelf();
	const { data: processCategories } = useProcessCategories();

	const match = useChildMatches();
	const currentTab: ActiveTab = match[0].routeId.split("/").pop() as ActiveTab;

	const [activeTab, setActiveTab] = useState<ActiveTab>(currentTab);

	const handleTabChange = (tab: ActiveTab) => {
		setActiveTab(tab);
		navigate({
			to: `../${tab}`,
		});
	};

	const { activeProcessCategories, setActiveProcessCategories } =
		useProcessCategoriesData();

	return (
		<div className="flex flex-col gap-6 mt-2">
			<div className="flex justify-between gap-2">
				<div className="flex flex-row gap-2 items-center">
					<Avatar
						size={42}
						src={getUserUri(user)}
						alt={getInitials(user?.firstName, user?.lastName)}
					/>
					<div>
						<span
							className="font-bold text-[1.1rem]"
							data-testid="your_work.welcome"
						>
							{intl.formatMessage({ id: "WELCOME" })}, {user?.firstName}!
						</span>
					</div>
				</div>
				<LogEvent />
				<Popover>
					<PopoverTrigger asChild>
						<Button variant="secondary">
							<GearIcon className="mr-2 h-4 w-4" />
							<FormattedMessage id="CONFIGURE_VIEW" />
						</Button>
					</PopoverTrigger>
					<PopoverContent
						className="flex flex-col gap-4 w-80"
						side="bottom"
						align="end"
					>
						<FormattedMessage id="ASSIGNEE_FILTER" />
						<RACIFilter />
						<div>
							<span>
								<FormattedMessage id="PROCESS_CATEGORIES" />
							</span>
							{processCategories && (
								<ComboBoxMultiSelect
									items={processCategories.map((p) => ({
										label: p.name,
										value: p.id,
									}))}
									value={activeProcessCategories}
									setValue={setActiveProcessCategories}
								/>
							)}
						</div>
					</PopoverContent>
				</Popover>
			</div>
			<div className="relative p-3">
				<div className="flex items-center gap-4 py-2">
					<Tabs
						onValueChange={(val: string) => handleTabChange(val as ActiveTab)}
						value={activeTab}
					>
						<TabsList className="bg-white bg-opacity-70">
							<TabsTrigger className="font-bold" value="steps">
								<FormattedMessage id="MY_ACTIVITIES" />
							</TabsTrigger>
							<TabsTrigger className="font-bold" value="processes">
								<FormattedMessage id="MY_PROCESSES" />
							</TabsTrigger>
						</TabsList>
					</Tabs>
					<KPIYourWork activeTab={currentTab} />
				</div>
			</div>
		</div>
	);
};
