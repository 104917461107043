import {
	EStepInstanceType,
	type ISchedule,
} from "@metronome/types/StepInstance";
import { TriangleRightIcon } from "@radix-ui/react-icons";
import { parseISO } from "date-fns";
import { cn } from "@metronome/utils";
import type { FC } from "react";
import { FormatDistance } from "@metronome/components/FormatDistance";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { formatObjectDate } from "@metronome/utils/dateHelpers";
import { EResolution } from "@metronome/types/Resolution";

type StepTimelineProps = {
	schedule: ISchedule;
	type: EStepInstanceType;
	resolution: EResolution;
};

const NowIcon = () => (
	<div className="absolute -bottom-[1.70rem] right-1/2 translate-x-1/2 text-center flex flex-col items-center z-10">
		<span className="text-transparent" aria-hidden>
			now
		</span>
		<svg
			className="w-3 h-3 text-red-400"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			fill="currentColor"
		>
			<title>Now</title>
			<circle cx="10" cy="10" r="10" />
		</svg>

		<span className="capitalize">
			<FormattedMessage id="NOW" />
		</span>
	</div>
);

type CircleDotProps = React.HTMLAttributes<HTMLDivElement>;
const CircleDotIcon: FC<CircleDotProps> = ({ className }) => (
	<div
		className={cn(
			"m-auto h-4 w-4 bg-white border-2 border-primary rounded-full",
			className,
		)}
	/>
);

const DateLabel = ({ date }: { date: Date }) => {
	const intl = useIntl();
	const { time, date: dateFormated } = formatObjectDate(date, intl);

	return (
		<div>
			<div className="text-xs">{dateFormated}</div>
			<span className="font-semibold text-xs">{time}</span>
		</div>
	);
};

type MilestoneTimelineProps = StepTimelineProps & {
	now: Date;
};

const MilestoneTimeline = ({
	schedule,
	now,
	resolution,
}: MilestoneTimelineProps) => {
	const targetStart = parseISO(schedule.scheduleLowerBand);
	const showNowMarkerBeforeMilestone =
		now < targetStart &&
		([EResolution.enum.notStarted] as ReadonlyArray<string>).includes(
			resolution,
		);

	const showNowMarkerAfterMilestone =
		now > targetStart &&
		([EResolution.enum.notStarted] as ReadonlyArray<string>).includes(
			resolution,
		);
	const isMilestoneDone = EResolution.enum.done === resolution;
	return (
		<div className="flex p-4 mt-6 mb-6">
			<div
				className={clsx({
					"border-primary border-dashed border-b-2 w-1/2 relative before:content-[''] before:absolute before:-bottom-[5px] before:-translate-x-1/2 before:h-2 before:rounded-full before:w-2 before:bg-primary": true,
				})}
			>
				{showNowMarkerBeforeMilestone && <NowIcon />}
			</div>
			<div className="border-gray-400 border-b-2 w-72 relative ">
				<div className="absolute -bottom-[1.80rem] -translate-x-1/2 text-center z-10">
					<DateLabel date={targetStart} />
					<CircleDotIcon
						className={clsx(isMilestoneDone ? "bg-primary" : "bg-white")}
					/>
					<FormattedMessage id="STEP_INSTANCE.TARGET_START.MILESTONE" />
				</div>
				{showNowMarkerAfterMilestone && <NowIcon />}
			</div>
			<div className="border-gray-400 border-b-2 w-10 relative ">
				<TriangleRightIcon className="absolute -bottom-2 -right-2 text-gray-400 scale-150" />
			</div>
		</div>
	);
};

export const StepTimeline = ({
	schedule,
	type,
	resolution,
}: StepTimelineProps) => {
	const { scheduleLowerBand, scheduleUpperBand, plannedAt } = schedule;
	const now = new Date();
	const lowerBandDate = parseISO(scheduleLowerBand);
	const upperBandDate = parseISO(scheduleUpperBand);

	const isStepDone = (
		[EResolution.enum.done, EResolution.enum.cancelled] as ReadonlyArray<string>
	).includes(resolution);
	const isBetweenStartAndDeadline = now > lowerBandDate && now < upperBandDate;
	const isBeforeStart = now < lowerBandDate;
	const isAfterDeadline = now > upperBandDate;

	if (type === EStepInstanceType.enum.milestone) {
		return (
			<MilestoneTimeline
				type={EStepInstanceType.enum.milestone}
				schedule={schedule}
				now={now}
				resolution={resolution}
			/>
		);
	}

	return (
		<div className="flex p-4 mt-6 mb-6">
			<div
				className={clsx({
					"border-primary border-b-2 w-44 relative before:content-[''] before:absolute before:-bottom-[5px] before:-translate-x-1/2 before:h-2 before:rounded-full before:w-2 before:bg-primary": true,
					"border-gray-500 before:bg-gray-500": isBeforeStart,
				})}
			>
				{isBeforeStart && <NowIcon />}
			</div>
			<div
				className={clsx({
					"relative w-full border border-gray-500 after:content-[''] after:absolute after:h-0.5 after:-translate-y-1/2 after:bg-primary": true,
					"after:bg-transparent": isBeforeStart,
					"after:w-1/2 border-dashed": isBetweenStartAndDeadline,
					"after:w-full": isAfterDeadline,
				})}
			>
				<div className="absolute -bottom-[1.80rem] -translate-x-1/2 text-center z-10">
					<DateLabel date={lowerBandDate} />
					<CircleDotIcon
						className={clsx(isBeforeStart ? "bg-white" : "bg-primary")}
					/>
					<span className="capitalize">
						<FormattedMessage id="START" />
					</span>
				</div>

				{isBetweenStartAndDeadline && <NowIcon />}
				<div className="absolute -bottom-[1.80rem] right-0 translate-x-1/2 z-[1] text-center">
					<DateLabel date={upperBandDate} />
					<CircleDotIcon
						className={clsx(isAfterDeadline ? "bg-primary" : "bg-white")}
					/>
					<span className="capitalize">
						<FormattedMessage id="END" />{" "}
						{isBetweenStartAndDeadline && <FormatDistance date={scheduleUpperBand} />}
					</span>
				</div>
			</div>
			<div className="border-gray-500 border-b-2 w-40 relative">
				{isAfterDeadline && !isStepDone && <NowIcon />}
				<TriangleRightIcon className="absolute -bottom-2 -right-2 text-gray-500 scale-150" />
			</div>
		</div>
	);
};
