import { useDeleteSavedTableViews } from "@metronome/api/useSaveTableViews";
import { useDeleteWorkspaceViews } from "@metronome/api/useWorkspaceViews";
import { ConfirmationModal } from "@metronome/components/ConfirmationModal";
import { Button } from "@metronome/components/ui/button";
import { useActiveViewData } from "@metronome/context/ActiveViewData";
import { TrashIcon } from "@radix-ui/react-icons";
import { useState } from "react";

type DeleteCustomViewProps = {
	preferenceKey: string;
};
export const DeleteCustomView: React.FC<DeleteCustomViewProps> = ({
	preferenceKey,
}) => {
	const [show, setShow] = useState(false);
	const { activeView, setActiveView } = useActiveViewData();

	const { mutate: deleteTableView, isPending } =
		useDeleteSavedTableViews(preferenceKey);
	const { mutate: deleteWorkspaceView, isPending: isWorkspacePending } =
		useDeleteWorkspaceViews(preferenceKey);

	const onDeleteView: () => void = () => {
		if (activeView) {
			if (activeView.type === "userView") {
				deleteTableView({
					key: activeView.id,
					successCallback: () => setActiveView(undefined),
				});
			}
			if (activeView.type === "workspaceView") {
				deleteWorkspaceView({
					key: activeView.id,
					successCallback: () => setActiveView(undefined),
				});
			}
		}
	};

	if (!activeView?.id) {
		return null;
	}

	return (
		<>
			<ConfirmationModal
				loading={isPending || isWorkspacePending}
				show={show}
				onClose={() => setShow(false)}
				onConfirm={onDeleteView}
			/>
			<Button
				onClick={() => setShow(true)}
				variant={"ghostDestructive"}
				size={"sm"}
			>
				<TrashIcon />
			</Button>
		</>
	);
};
