import { FallbackView } from "@metronome/components/FallbackView";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import { PageTitle } from "@metronome/context/PageData";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import type React from "react";
import { useIntl } from "react-intl";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import useUserPreferences from "@metronome/api/useUserPreference";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import type { IProcessStream } from "@metronome/types/ProcessStream";

type ProcessStreamPageProps = {
	processStream: IProcessStream;
};

export const ProcessStreamPage: React.FC<ProcessStreamPageProps> = ({
	processStream,
}) => {
	const intl = useIntl();

	const { organizations } = useOrganizationData();

	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences, isLoading: isUserPrefLoading } =
		useUserPreferences(PREFERENCE_KEY);

	useSetCurrentOrganization(processStream?.organization.id);

	if (!processStream) {
		return <FallbackView />;
	}

	const statesMapper = {
		active: ["active" as const],
		upcoming: ["active" as const, "ready" as const],
		past: ["closed" as const, "archived" as const],
	};

	const timesMapper = {
		active: ["past" as const, "today" as const],
		upcoming: ["future" as const],
		past: ["past" as const],
	};

	if (isUserPrefLoading) {
		return <LoadingMetronome />;
	}

	return (
		<ProcessStreamTemplate processStream={processStream} isLoading={false}>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
			</PageTitle>
			<ActiveViewProvider preferences={preferences}>
				<UserProcessConfigLoader
					context={state as ProcessContext}
					key={state}
					states={statesMapper[state as keyof typeof statesMapper]}
					times={timesMapper[state as keyof typeof timesMapper]}
					processStreamId={processStream.id}
					preferenceKey={PREFERENCE_KEY}
					preferences={preferences}
				/>
			</ActiveViewProvider>
		</ProcessStreamTemplate>
	);
};
