import { singleProcessOptions } from "@metronome/api/useProcessInstance";
import { SchedulerWrapper } from "@metronome/features/Scheduler";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/$workspaceId/process/$processId/timeline",
)({
	loader: ({ context, params }) =>
		context?.queryClient.ensureQueryData(
			singleProcessOptions(params.workspaceId, params.processId),
		),
	component: () => {
		const { workspaceId, processId } = Route.useParams();
		const { data: processInstance } = useQuery(
			singleProcessOptions(workspaceId, processId),
		);

		return (
			<div className="p-2 h-[70vh] bg-grid-scheduler">
				<SchedulerWrapper
					key={processInstance.id}
					id={processInstance.id}
					name={processInstance.name}
					plannedStart={processInstance.schedule?.plannedOn}
					plannedEnd={processInstance.schedule?.dueOn}
				/>
			</div>
		);
	},
});
