import useMetadataDefinitions from "@metronome/api/useMetadataDefinitions";
import type { ILightBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import { FormattedMessage } from "react-intl";
import { EditableMetadataValues } from "../MetadataList";
import { DisplayMetadataValue } from "../MetadataList/DisplayMetadataValues";
import styles from "./featuredMetadata.module.scss";

type FeaturedMetadataProps = {
	contextId: string;
	context: Context;
	businessDimension: ILightBusinessDimensionNode;
};

export const FeaturedMetadata: React.FC<FeaturedMetadataProps> = ({
	contextId,
	context,
	businessDimension,
}) => {
	const { data: metaDataDefinitions } = useMetadataDefinitions({
		nodeIds: businessDimension.id,
	});

	return (
		<div>
			<strong>
				<FormattedMessage id="FEATURED_METADATA" />
			</strong>
			<div className={styles.gridContainerMetadata}>
				{metaDataDefinitions
					?.filter((def) => def.isFeatured)
					?.map((def) => {
						const values = businessDimension.metadataValues.find(
							(value) => value.definition.id === def.id,
						);
						return def.canBeEdited ? (
							<EditableMetadataValues
								nodeId={businessDimension.id}
								key={def.id}
								contextId={contextId}
								context={context}
								definition={def}
								values={values}
							/>
						) : (
							<DisplayMetadataValue
								key={def.id}
								metadataValue={values}
								definition={def}
							/>
						);
					})}
			</div>
		</div>
	);
};
