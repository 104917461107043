import { useFilteredStepInstances } from "@metronome/api/useStepInstance";
import * as DataGrid from "@metronome/components/DataGrid";
import {
	EStepInstanceType,
	type IFilters,
	type IStepInstance,
	type StepInstanceContext,
} from "@metronome/types/StepInstance";
import { fuzzyFilter, updateColumnFilters } from "@metronome/utils/tableHelper";
import * as Sentry from "@sentry/browser";
import {
	type CellContext,
	type ColumnDef,
	type ColumnFiltersState,
	type ColumnOrderState,
	type ColumnPinningState,
	type PaginationState,
	type SortingState,
	type Updater,
	type VisibilityState,
	createColumnHelper,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { z } from "zod";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { SavedTable } from "@metronome/api/useSaveTableViews";
import { useUpdateUserPreferences } from "@metronome/api/useUserPreference";
import { AvatarWithName } from "@metronome/components/AvatarWithName";
import { AvatarsList } from "@metronome/components/AvatarsList";
import { BlockedIcon } from "@metronome/components/BlockedIcon";

import DateTimeCell from "@metronome/components/DateCell";
import Flag from "@metronome/components/Flag";
import { FormatDistance } from "@metronome/components/FormatDistance";
import { HighlightWords } from "@metronome/components/HighlightWords";
import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import { Lozenge } from "@metronome/components/Lozenge";
import { NodeFilterComponent } from "@metronome/components/NodeFilterComponent";
import { PartyFilterComponent } from "@metronome/components/PartyFilterComponent";
import { StepFilterComponent } from "@metronome/components/StepFilterComponent";
import { TypeFilterComponent } from "@metronome/components/StepTypeFilterComponent";
import { TableContainer } from "@metronome/components/TableContainer/TableContainer";
import { TimelinessLozenge } from "@metronome/components/TimelinessTag";
import { Button } from "@metronome/components/ui/button";
import {
	COMPLIANCE,
	DUE_DATE,
	DURATION,
	LABELS,
	NAME,
	NODES_TYPE,
	OCCURRENCE,
	PROCESS_STREAM_NAME,
} from "@metronome/constants/stepInstanceColumns";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import { useProcessCategoriesData } from "@metronome/context/ProcessCategoriesData";
import { AddSingleMetadata } from "@metronome/features/AddSingleMetadata";
import { EditSingleMetadata } from "@metronome/features/EditSingleMetadata";
import { OrganizationName } from "@metronome/features/OrganizationName";
import { ResponsibleUser } from "@metronome/features/ResponsibleUser";
import { UpdateResolution } from "@metronome/features/UpdateResolution";
import { UpdateStepInstanceDate } from "@metronome/features/UpdateStepInstanceDate";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import type { ProcessState } from "@metronome/types/ProcessInstance";
import { EResolution } from "@metronome/types/Resolution";
import type { ERoles } from "@metronome/types/Roles";
import { EStepTimeliness, type ETimeliness } from "@metronome/types/Timeliness";
import { atEndOfDay, atMidnight } from "@metronome/utils/dateHelpers";
import { formatDurationToString } from "@metronome/utils/durationHelpers";
import { isWithinRange } from "@metronome/utils/isWithinRange";
import { getTimelinessFromStepInstance } from "@metronome/utils/timeliness";
import { ErrorBoundary } from "@sentry/react";

import { useActiveViewData } from "@metronome/context/ActiveViewData";
import { SelectCustomView } from "@metronome/features/SelectCustomView";
import type { UserPreference } from "@metronome/types/UserPreference";
import { Cross1Icon, GearIcon } from "@radix-ui/react-icons";
import { endOfToday } from "date-fns/endOfToday";
import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import { startOfToday } from "date-fns/startOfToday";
import { StepSchedule } from "@metronome/features/StepSchedule";
import { getIsResolutionDoneOrCancel } from "@metronome/utils/resolution";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import { DeleteCustomView } from "./DeleteCustomView";

type Props = {
	preferenceKey: string;
};
const CleanUserPreference = ({ preferenceKey }: Props): JSX.Element => {
	const { mutate } = useUpdateUserPreferences(preferenceKey);

	const reload = useCallback(() => {
		Sentry.captureMessage("user clicked clean and reload");
		mutate({
			data: {
				columnFilters: undefined,
				columnPinning: undefined,
				columnVisibility: undefined,
			},
		});
		window.location.reload();
	}, [mutate]);

	return (
		<div className="flex flex-col items-center gap-2">
			<span>
				<FormattedMessage id="TABLE_CORRUPTED" />
			</span>
			<Button variant="ghost" onClick={reload}>
				<FormattedMessage id="CLEAN_AND_RELOAD" />
			</Button>
		</div>
	);
};

const EMPTY_ARRAY: Array<never> = [];
const EMPTY_OBJECT = {};

// beware the following constants might be saved in the client LocalStorage
const PROCESS_TEMPLATE_NAME = "processInstance.processTemplate.name";

const RESOLUTION = "resolution";
const TIMELINESS = "timeliness";
const RANK = "rank";
const ASSIGNEE = "assignments";
const RESPONSIBLE_LIST = "RESPONSIBLE_LIST";
const RESPONSIBLE_IDS = "responsibleIds";
const COMPLETED_AT = "completion.performedAt";
const FLAG = "isFlagged";
const STEP_TYPE = "type";
const ORGANIZATION_ID = "processInstance.organizationId";
const DEADLINE = "DEADLINE";
const COMPLETED_BY = "COMPLETED_BY";
const START_FROM = "startFrom";
const START_TO = "startTo";
const SCHEDULE = "schedule";

const allowedFilterColumn = [
	"",
	PROCESS_STREAM_NAME,
	OCCURRENCE,
	NAME,
	ASSIGNEE,
	RESPONSIBLE_IDS,
	DUE_DATE,
	FLAG,
	RESOLUTION,
	TIMELINESS,
	COMPLETED_AT,
	COMPLETED_BY,
	COMPLIANCE,
	START_TO,
	START_FROM,
] as const;

const allowedSortColumn = [
	PROCESS_STREAM_NAME,
	OCCURRENCE,
	NAME,
	ASSIGNEE,
	DUE_DATE,
	FLAG,
	RESOLUTION,
	COMPLETED_AT,
	RANK,
	LABELS,
] as const;

const SortByAllowedStrings = z.enum(allowedSortColumn);
type SortByAllowedStrings = z.infer<typeof SortByAllowedStrings>;

const getSortByTerm = (sortBy?: string): SortByAllowedStrings | undefined => {
	const result = SortByAllowedStrings.safeParse(sortBy);
	return result.success ? result.data : undefined;
};

type LinkHighLightedProps = {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	info: CellContext<IStepInstance, any>;
	children?: React.ReactNode;
	context?: StepInstanceContext;
};
const LinkHighLighted: React.FC<LinkHighLightedProps> = ({
	info,
	children,
	context,
}) => {
	const workspaceId = useWorkspaceId();
	return (
		<Link
			to={
				context === "your-work"
					? "/$workspaceId/your-work/steps/modal/"
					: "/$workspaceId/process/$processId/gates-and-steps/$stepId"
			}
			params={{
				workspaceId,
				processId: info.row.original.processInstance.id,
				stepId: info.row.original.id,
			}}
			search={{
				stepId: info.row.original.id,
			}}
		>
			{children ?? (
				<HighlightWords
					content={info.getValue()}
					query={info.table.getState().globalFilter}
				/>
			)}
		</Link>
	);
};

const columnHelpers = createColumnHelper<IStepInstance>();
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useDefaultColumns(context: StepInstanceContext, filters: IFilters) {
	const workspaceId = useWorkspaceId();
	const intl = useIntl();
	const translatedStatusResolution = EResolution.options.map((status) => {
		switch (status) {
			case EResolution.enum.notStarted:
				return {
					name: intl.formatList(
						[
							intl.formatMessage({ id: `RESOLUTION.event.${status}` }),
							intl.formatMessage({ id: `RESOLUTION.task.${status}` }),
							intl.formatMessage({ id: `RESOLUTION.milestone.${status}` }),
						],
						{
							type: "disjunction",
						},
					),
					id: status,
				};
			case EResolution.enum.cancelled:
			case EResolution.enum.onHold:
			case EResolution.enum.inProgress:
				return {
					name: intl.formatList([
						intl.formatMessage({ id: `RESOLUTION.${status}` }),
					]),
					id: status,
				};

			case EResolution.enum.done:
				return {
					name: intl.formatList(
						[
							intl.formatMessage({ id: `RESOLUTION.${status}` }),
							intl.formatMessage({ id: `RESOLUTION.milestone.${status}` }),
						],
						{
							type: "disjunction",
						},
					),
					id: status,
				};
		}
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: waiting for react compiler
	const columns = useMemo(
		() => [
			columnHelpers.accessor(STEP_TYPE, {
				id: STEP_TYPE,
				enableSorting: false,
				footer: (props) => props.column.id,
				filterFn: "arrIncludes",
				meta: {
					filterComponent: TypeFilterComponent,
				},
				header: () => <FormattedMessage id="PROCESS_TEMPLATE.TYPE" />,
				cell: (info) => (
					<div className="flex items-center justify-center">
						<LinkHighLighted context={context} info={info}>
							<StepInstanceIcon type={info.getValue()} />
						</LinkHighLighted>
					</div>
				),
			}),
			columnHelpers.accessor(PROCESS_STREAM_NAME, {
				id: PROCESS_STREAM_NAME,
				filterFn: "arrIncludes",
				footer: (props) => props.column.id,
				meta: { modelRefArray: filters?.processStreams },
				header: () => <FormattedMessage id="YOUR_WORK.STEPS.PROCESS" />,
				cell: (info) => <LinkHighLighted info={info} />,
			}),
			columnHelpers.accessor(
				(row) =>
					intl.formatMessage(
						{ id: "YOUR_WORK.STAGE_STEP_RANK" },
						{
							stageRank: row.stage.rank + 1,
							stepRank: row.step.rank + 1,
						},
					),
				{
					id: RANK,
					enableColumnFilter: false,
					footer: (props) => props.column.id,
					header: () => <FormattedMessage id="RANK" />,
					cell: (info) => info.getValue(),
				},
			),
			columnHelpers.accessor("step.name", {
				id: NAME,
				filterFn: "arrIncludes",
				meta: {
					stepFilterComponent: StepFilterComponent,
					stepFilters: filters?.stepTemplates,
				},
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="YOUR_WORK.STEPS.NAME" />,
				cell: (info) => (
					<>
						{info.row.original.isBlocked && (
							<BlockedIcon tooltipMessage="BLOCKED_STEP" />
						)}
						<LinkHighLighted info={info} />
					</>
				),
			}),
			columnHelpers.accessor("businessDimension", {
				id: OCCURRENCE,
				filterFn: "arrIncludes",
				meta: {
					nodeFilterComponent: NodeFilterComponent,
				},
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="YOUR_WORK.STEPS.CONTEXT" />,
				cell: (info) => (
					<LinkHighLighted info={info}>
						<HighlightWords
							content={info.getValue().name}
							query={info.table.getState().globalFilter}
						/>
					</LinkHighLighted>
				),
			}),
			columnHelpers.accessor(NODES_TYPE, {
				id: NODES_TYPE,
				filterFn: "arrIncludes",
				enableSorting: false,
				meta: {
					modelRefArray: filters?.nodeTypes,
				},
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="NODE_TYPE" />,
				cell: (info) => (
					<LinkHighLighted info={info}>
						{!info.getValue().nodeType?.name && (
							<Lozenge key={info.getValue().id} appearance="default">
								{info.getValue().nodeType?.name}
							</Lozenge>
						)}
					</LinkHighLighted>
				),
			}),
			columnHelpers.accessor((row) => row.businessDimension.labels ?? [], {
				id: LABELS,
				filterFn: "arrIncludes",
				enableSorting: false,
				meta: {
					modelRefArray: filters?.labels,
				},
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="LABELS" />,
				cell: (info) => (
					<LinkHighLighted info={info}>
						<div className="flex gap-2">
							{info
								.getValue()
								?.sort((a, b) => a.localeCompare(b))
								.map((label: string) => (
									<Lozenge key={label} appearance="default">
										{label}
									</Lozenge>
								))}
						</div>
					</LinkHighLighted>
				),
			}),
			columnHelpers.accessor("schedule", {
				id: SCHEDULE,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="SCHEDULE" />,
				cell: (info) => <StepSchedule schedule={info.row.original.schedule} />,
			}),
			columnHelpers.accessor("schedule.targetStart", {
				id: DUE_DATE,
				filterFn: isWithinRange,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="YOUR_WORK.STEPS.DUE_DATE" />,
				cell: (info) => {
					const val = info.getValue();
					if (val && isProcessActive(info.row.original.processInstance.state)) {
						return (
							<UpdateStepInstanceDate
								key={info.row.original.id}
								stepInstanceId={info.row.original.id}
								processInstanceId={info.row.original.processInstance.id}
								typeOfSchedule="target-date"
								initialDate={parseISO(val)}
							/>
						);
					}
					return <span>{val}</span>;
				},
				meta: {
					type: "date",
				},
			}),
			columnHelpers.accessor("schedule.estimatedDuration", {
				id: DURATION,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="DURATION" />,
				cell: (info) => (
					<LinkHighLighted info={info}>
						{formatDurationToString(info.getValue())}
					</LinkHighLighted>
				),
			}),
			columnHelpers.accessor("schedule.deadline", {
				id: DEADLINE,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="DEADLINE" />,
				cell: (info) => {
					const val = info.getValue();
					if (info.row.original.type === "milestone") return <span>n/a</span>;
					if (
						getIsResolutionDoneOrCancel(info.row.original.resolution) ||
						!isProcessActive(info.row.original.processInstance.state)
					) {
						return <span>{val}</span>;
					}
					if (val) {
						return (
							<UpdateStepInstanceDate
								key={info.row.original.id}
								stepInstanceId={info.row.original.id}
								processInstanceId={info.row.original.processInstance.id}
								typeOfSchedule="deadline"
								initialDate={parseISO(val)}
							/>
						);
					}
					return null;
				},
				meta: {
					type: "date",
				},
			}),
			columnHelpers.accessor(RESOLUTION, {
				id: RESOLUTION,
				footer: (props) => props.column.id,
				filterFn: "arrIncludes",
				meta: {
					modelRefArray: translatedStatusResolution,
				},
				header: () => <FormattedMessage id="STATE" />,
				cell: (info) => {
					if (
						getIsResolutionDoneOrCancel(info.row.original.resolution) ||
						!isProcessActive(info.row.original.processInstance.state)
					) {
						return (
							<LinkHighLighted info={info}>
								<Lozenge appearance="default">
									<FormattedMessage
										id={`RESOLUTION.${info.row.original.resolution}`}
									/>
								</Lozenge>
							</LinkHighLighted>
						);
					}
					return (
						<UpdateResolution
							useSyncUpdate
							key={info.row.original.id}
							stepInstance={info.row.original}
						/>
					);
				},
			}),
			columnHelpers.accessor(
				(row) =>
					getTimelinessFromStepInstance(row.schedule, row.completion, row.type),
				{
					id: TIMELINESS,
					enableSorting: false,
					filterFn: "arrIncludes",
					meta: {
						modelRefArray: [
							EStepTimeliness.enum.onTrack,
							EStepTimeliness.enum.late,
						].map((p) => ({
							id: p,
							name: intl.formatMessage({ id: `TIMELINESS.${p}` }),
						})),
					},
					footer: (props) => props.column.id,
					header: () => <FormattedMessage id="TIMELINESS" />,
					cell: (info) => (
						<LinkHighLighted info={info}>
							<TimelinessLozenge timeliness={info.getValue()} />
						</LinkHighLighted>
					),
				},
			),
			columnHelpers.accessor("isFlagged", {
				id: FLAG,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="FLAG" />,
				cell: (info) => (
					<Flag
						isFlagged={!!info.getValue()}
						id={info.row.original.id}
						context="step-instances"
					/>
				),
			}),
		],
		[context, intl, workspaceId],
	);

	return columns;
}

function useYourWorkColumns(context: StepInstanceContext) {
	const columns = useMemo(
		() => [
			columnHelpers.accessor(
				(row) =>
					row.assignments.responsibleAssignees.map(
						(assignee) => `${assignee.firstName} ${assignee.lastName}`,
					),
				{
					id: ASSIGNEE,
					filterFn: "arrIncludes",
					enableColumnFilter: true,
					meta: {
						partyFilterComponent: PartyFilterComponent,
					},
					footer: (props) => props.column.id,
					header: () => <FormattedMessage id="ASSIGNEE" />,
					cell: (info) => (
						<ResponsibleUser
							responsibleAssignees={
								info.row.original.assignments.responsibleAssignees
							}
						/>
					),
				},
			),
		],
		[],
	);
	if (context !== "your-work") return EMPTY_ARRAY;

	return columns;
}

function useActivitiesColumns(context: StepInstanceContext) {
	const columns = useMemo(
		() => [
			columnHelpers.accessor(PROCESS_TEMPLATE_NAME, {
				id: PROCESS_TEMPLATE_NAME,
				enableSorting: false,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="PROCESS_TEMPLATE.NAME" />,
				cell: (info) => <LinkHighLighted info={info} />,
			}),
			columnHelpers.accessor("processInstance.name", {
				id: "INSTANCE",
				enableSorting: false,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="INSTANCE" />,
				cell: (info) => <LinkHighLighted info={info} />,
			}),
			columnHelpers.accessor(ORGANIZATION_ID, {
				id: ORGANIZATION_ID,
				enableSorting: false,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="ORGANIZATION" />,
				cell: (info) => <OrganizationName organizationId={info.getValue()} />,
			}),
			columnHelpers.accessor(
				(row) =>
					row.assignments.responsibleAssignees.map(
						(assignee) => `${assignee.firstName} ${assignee.lastName}`,
					),
				{
					id: RESPONSIBLE_LIST,
					filterFn: "arrIncludes",
					enableColumnFilter: true,
					meta: {
						partyFilterComponent: PartyFilterComponent,
					},
					footer: (props) => props.column.id,
					header: () => <FormattedMessage id="ASSIGNEE" />,
					cell: (info) => (
						<AvatarsList
							wideSpacing={false}
							users={info.row.original.assignments.responsibleAssignees}
						/>
					),
				},
			),
			columnHelpers.accessor((row) => row?.completion?.performedBy?.email, {
				id: COMPLETED_BY,
				enableSorting: false,
				meta: { partyFilterComponent: PartyFilterComponent },
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="COMPLETED_BY" />,
				cell: (info) => (
					<span>
						{info.row.original.completion?.performedBy && (
							<AvatarWithName
								key={`perform-by-${info.row.original.completion?.performedBy.id}`}
								user={info.row.original.completion.performedBy}
							/>
						)}
					</span>
				),
			}),
			columnHelpers.accessor(
				(row) => new Date(row?.completion?.performedAt ?? "").toJSON(),
				{
					id: COMPLETED_AT,
					filterFn: isWithinRange,
					footer: (props) => props.column.id,
					header: () => <FormattedMessage id="COMPLETED_AT" />,
					cell: (info) => {
						if (info.getValue()) {
							return <DateTimeCell datetime={info.getValue()} />;
						}
						return null;
					},
					meta: {
						type: "date",
					},
				},
			),
			columnHelpers.accessor((row) => row.completion?.performedBy?.email, {
				id: COMPLIANCE,
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="COMPLIANCE" />,
				cell: (info) => {
					if (!info.row.original.completion?.performedBy.id) return "";
					const isCompliant =
						info.row.original.assignments.responsibleAssignees.find(
							(assignee) =>
								assignee.id === info.row.original.completion?.performedBy.id,
						);
					if (isCompliant)
						return (
							<FontAwesomeIcon
								className="text-green-500"
								icon={["fas", "circle-check"]}
							/>
						);
					return (
						<FontAwesomeIcon
							className="text-red-500"
							icon={["fas", "circle-xmark"]}
						/>
					);
				},
			}),
			columnHelpers.accessor((row) => row.completion?.performedBy.email, {
				id: "VIEW_BY_RESPONSIBLE",
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="VIEW_BY_RESPONSIBLE" />,
				cell: (info) => {
					if (!info.row.original.views?.length) return "";
					const isCompliant =
						info.row.original.assignments.responsibleAssignees.some(
							(assignee) =>
								info.row.original.views?.some(
									(v) => v.party.id === assignee.id && v.raci === "responsible",
								),
						);
					if (isCompliant)
						return (
							<FontAwesomeIcon
								className="text-green-500"
								icon={["fas", "circle-check"]}
							/>
						);
					return (
						<FontAwesomeIcon
							className="text-red-500"
							icon={["fas", "circle-xmark"]}
						/>
					);
				},
			}),
			columnHelpers.accessor((row) => row.completion?.performedBy.email, {
				id: "VIEW_BY_ACCOUNTABLE",
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="VIEW_BY_ACCOUNTABLE" />,
				cell: (info) => {
					if (!info.row.original.views?.length) return "";
					const isCompliant =
						info.row.original.assignments.responsibleAssignees.some(
							(assignee) =>
								info.row.original.views?.some(
									(v) => v.party.id === assignee.id && v.raci === "accountable",
								),
						);
					if (isCompliant)
						return (
							<FontAwesomeIcon
								className="text-green-500"
								icon={["fas", "circle-check"]}
							/>
						);
					return (
						<FontAwesomeIcon
							className="text-red-500"
							icon={["fas", "circle-xmark"]}
						/>
					);
				},
			}),
			columnHelpers.accessor(
				(row) => {
					const { resolution } = row;
					if (
						resolution === EResolution.enum.cancelled ||
						resolution === EResolution.enum.done
					) {
						return "done";
					}
					return row?.schedule.lowerBand;
				},
				{
					id: "LIVE_STATUS",
					enableColumnFilter: false,
					filterFn: isWithinRange,
					footer: (props) => props.column.id,
					header: () => <FormattedMessage id="LIVE_STATUS" />,
					cell: (info) => (
						<div>
							{info.getValue() !== "done" ? (
								<FormatDistance date={info.getValue()} />
							) : (
								<Lozenge appearance="default">
									<FormattedMessage id="RESOLUTION.done" />
								</Lozenge>
							)}
						</div>
					),
					meta: {
						type: "date",
					},
				},
			),
		],
		[],
	);
	if (context !== "activities") return EMPTY_ARRAY;

	return columns;
}

const defaultVisibilityState: VisibilityState = {
	PROCESS_TEMPLATE_NAME: false,
	ORGANIZATION_ID: false,
	STEP_TYPE: false,
};

const StepInstancesTab: React.FC<{
	resolutions: EResolution[];
	processStates?: ProcessState[];
	roles?: ERoles[];
	context: StepInstanceContext;
	preferenceKey: string;
	preferences?: UserPreference;
	savedViews?: SavedTable[];
	workspaceViews?: SavedTable[];
	metadataDefinitions?: IMetadataDefinition[];
	filters: IFilters;
}> = ({
	resolutions,
	processStates,
	roles,
	context,
	preferenceKey,
	preferences,
	savedViews,
	workspaceViews,
	metadataDefinitions,
	filters,
}) => {
	const navigate = useNavigate();
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();
	const { activeView } = useActiveViewData();
	const [dirty, setDirty] = useState(false);

	const defaultColumns = useDefaultColumns(context, filters);
	const yourWorkColumns = useYourWorkColumns(context);
	const activitiesColumns = useActivitiesColumns(context);
	const searchParams = useSearch({
		from:
			context === "your-work"
				? "/$workspaceId/your-work/steps"
				: "/$workspaceId/activities",
	});
	const { sortBy, sortOrder, search } = searchParams;

	const globalSearch = useMemo(() => search ?? "", [search]);

	const searchFilters = useMemo(
		() =>
			Object.entries(searchParams)
				.filter(
					([key, value]) =>
						(allowedFilterColumn as ReadonlyArray<string>).includes(key) &&
						value,
				)
				.map(([key, value]) => {
					if (key === "processIds") {
						return {
							id: PROCESS_STREAM_NAME,
							value,
						};
					}
					if (key === TIMELINESS) {
						return {
							id: TIMELINESS,
							value: [value],
						};
					}
					if (key === RESOLUTION) {
						return {
							id: RESOLUTION,
							value: value,
						};
					}
					if (key === "responsibleIds") {
						return {
							id: RESPONSIBLE_LIST,
							value,
						};
					}
					if (key === OCCURRENCE) {
						return {
							id: OCCURRENCE,
							value,
						};
					}
					if (key === LABELS)
						return {
							id: LABELS,
							value,
						};

					if (key === COMPLETED_BY)
						return {
							id: "completedBy",
							value,
						};

					if (key === NODES_TYPE)
						return {
							id: NODES_TYPE,
							value,
						};
					const dueDate = { id: DUE_DATE, value: ["", ""] };
					if (key === START_FROM) {
						dueDate.value[0] = value as string;
					}
					if (key === START_TO) {
						dueDate.value[1] = value as string;
					}
					return { id: "", value: "" };
				}),
		[searchParams],
	);

	const hideMetaColumns = useMemo(
		() =>
			metadataDefinitions?.reduce(
				(acc, meta) => {
					acc[meta.id] = false;
					return acc;
				},
				{} as Record<string, boolean>,
			),
		[metadataDefinitions],
	);

	const initialColumnVisibility = useMemo(() => {
		// if (Object.keys(searchColumns).length) return searchColumns;
		if (savedViews?.length && activeView?.type === "userView") {
			const columnVisibility = savedViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnVisibility;

			if (columnVisibility) {
				return columnVisibility;
			}
		}
		if (workspaceViews?.length && activeView?.type === "workspaceView") {
			const columnVisibility = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnVisibility;

			if (columnVisibility) {
				return columnVisibility;
			}
		}
		if (hideMetaColumns) {
			return hideMetaColumns;
		}
		return defaultVisibilityState;
	}, [
		activeView?.id,
		activeView?.type,
		hideMetaColumns,
		savedViews,
		workspaceViews,
	]);

	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
		initialColumnVisibility,
	);

	const initialColumnFilters = useMemo(() => {
		if (savedViews && activeView?.type === "userView") {
			const columnFilters = savedViews.find((view) => view.id === activeView.id)
				?.settings.columnFilters;
			if (columnFilters) {
				return updateColumnFilters(columnFilters, filters);
			}
		}
		if (workspaceViews && activeView?.type === "workspaceView") {
			const columnFilters = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnFilters;
			if (columnFilters) {
				return updateColumnFilters(columnFilters, filters);
			}
		}
		if (searchFilters.length > 0) return searchFilters;
		// be default we filter on today
		const from = format(startOfToday(), "yyyy-MM-dd");
		const to = format(endOfToday(), "yyyy-MM-dd");
		return [
			{
				id: DUE_DATE,
				value: [from, to],
			},
		];
	}, [
		savedViews,
		activeView?.type,
		activeView?.id,
		workspaceViews,
		filters,
		searchFilters,
	]);

	const [columnFilters, setColumnFilters] =
		useState<ColumnFiltersState>(initialColumnFilters);

	const initialColumnPinning = useMemo(() => {
		if (savedViews && activeView?.type === "userView") {
			const columnPinning = savedViews.find((view) => view.id === activeView.id)
				?.settings.columnPinning;
			if (columnPinning) {
				return columnPinning;
			}
		}
		if (workspaceViews && activeView?.type === "workspaceView") {
			const columnPinning = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnPinning;
			if (columnPinning) {
				return columnPinning;
			}
		}
		return EMPTY_OBJECT;
	}, [activeView?.id, activeView?.type, savedViews, workspaceViews]);

	const [columnPinning, setColumnPinning] =
		React.useState<ColumnPinningState>(initialColumnPinning);

	const { activeProcessCategories } = useProcessCategoriesData();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const columns = useMemo(() => {
		let metaColumns: Array<ColumnDef<IStepInstance, string | undefined>> = [];
		if (Array.isArray(metadataDefinitions)) {
			metaColumns = metadataDefinitions.map((meta) =>
				columnHelpers.accessor(
					(row) => {
						const getMetaData = row.businessDimension?.metadataValues.find(
							(metaData) => metaData.definition.id === meta.id,
						);
						const stringifiedValue = Array.isArray(getMetaData?.value)
							? getMetaData?.value.join(", ")
							: getMetaData?.value;
						return stringifiedValue;
					},
					{
						id: meta.id,
						enableColumnFilter: false,
						footer: (props) => props.column.id,
						meta: {
							treeName: meta.tree.name,
						},
						header: () => meta.name,
						cell: (info) =>
							!info?.getValue() ? (
								<AddSingleMetadata
									metadataDefinition={meta}
									instance={info.row.original}
									context="step-instances"
								/>
							) : (
								<EditSingleMetadata
									metadataDefinition={meta}
									instance={info.row.original}
									context="step-instances"
									query={info.table.getState().globalFilter}
								/>
							),
					},
				),
			);
		}

		return [
			...defaultColumns,
			...activitiesColumns,
			...yourWorkColumns,
			...metaColumns,
		];
	}, [
		metadataDefinitions,
		yourWorkColumns,
		activitiesColumns,
		defaultColumns,
		workspaceId,
		context,
	]);

	const initialColumnOrder = useMemo(() => {
		if (savedViews && activeView?.type === "userView") {
			const columnOrder = savedViews.find((view) => view.id === activeView.id)
				?.settings.columnOrder;
			if (columnOrder) {
				return columnOrder;
			}
		}
		if (workspaceViews && activeView?.type === "workspaceView") {
			const columnOrder = workspaceViews.find(
				(view) => view.id === activeView.id,
			)?.settings.columnOrder;
			if (columnOrder) {
				return columnOrder;
			}
		}
		return columns.map((col, i) => col.id ?? i.toString());
	}, [activeView?.id, activeView?.type, columns, savedViews, workspaceViews]);

	const [columnOrder, setColumnOrder] =
		useState<ColumnOrderState>(initialColumnOrder);

	const [powerMode, setPowerMode] = useState(false);
	const [globalFilter, setGlobalFilter] = React.useState(globalSearch);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: preferences?.pageSize ?? 25,
	});

	const [sorting, setSorting] = React.useState<SortingState>(
		sortBy
			? [
					{
						id: sortBy ?? "",
						desc: sortOrder === "desc",
					},
				]
			: EMPTY_ARRAY,
	);

	const {
		data: stepInstances,
		isLoading: isLoadingStepInstances,
		isFetching,
		isFetched,
	} = useFilteredStepInstances(
		{
			organizationId: activeOrganization,
			page: pagination.pageIndex + 1,
			pageSize: pagination.pageSize,
			sortOrder: sorting?.[0]?.desc ? "desc" : "asc",
			sortBy: getSortByTerm(sorting?.[0]?.id),
			search: globalFilter,
			processIds: columnFilters?.find(
				(filter) => filter.id === PROCESS_STREAM_NAME,
			)?.value as string,
			labelIds: columnFilters?.find((filter) => filter.id === LABELS)
				?.value as string[],
			nodeIds: columnFilters.find((filter) => filter.id === OCCURRENCE)
				?.value as string,
			stepTemplateIds: columnFilters.find((filter) => filter.id === NAME)
				?.value as string,
			responsibleIds: columnFilters.find(
				(filter) =>
					filter.id ===
					(context === "activities" ? RESPONSIBLE_LIST : ASSIGNEE),
			)?.value as string,
			nodeTypeIds: columnFilters?.find((filter) => filter.id === NODES_TYPE)
				?.value as string,
			statuses:
				(columnFilters.find((filter) => filter.id === RESOLUTION)
					?.value as string) ?? resolutions,
			stepTypes:
				(columnFilters.find((filter) => filter.id === STEP_TYPE)
					?.value as string) ??
				EStepInstanceType.options.filter(
					(t) => t !== EStepInstanceType.Enum.hook,
				),
			processStates,
			completedByIds:
				(columnFilters.find((filter) => filter.id === "COMPLETED_BY")
					?.value as string[]) ?? [],
			processCategoryIds: activeProcessCategories,
			raci: roles,
			timelinesses:
				(columnFilters.find((filter) => filter.id === TIMELINESS)
					?.value as string[]) ?? [],
			startFrom: columnFilters.length
				? atMidnight(
						(
							columnFilters.find((filter) => filter.id === DUE_DATE)
								?.value as string[]
						)?.at(0),
					)?.toJSON()
				: undefined,
			startTo: columnFilters.length
				? atEndOfDay(
						(
							columnFilters.find((filter) => filter.id === DUE_DATE)
								?.value as string[]
						)?.at(1),
					)?.toJSON()
				: undefined,
			deadlineFrom: columnFilters.length
				? atMidnight(
						(
							columnFilters.find((filter) => filter.id === DEADLINE)
								?.value as string[]
						)?.at(0),
					)?.toJSON()
				: undefined,
			deadlineTo: columnFilters.length
				? atEndOfDay(
						(
							columnFilters.find((filter) => filter.id === DEADLINE)
								?.value as string[]
						)?.at(1),
					)?.toJSON()
				: undefined,
			completedFrom: columnFilters.length
				? atMidnight(
						(
							columnFilters.find((filter) => filter.id === COMPLETED_AT)
								?.value as string[]
						)?.at(0),
					)?.toJSON()
				: undefined,
			completedTo: columnFilters.length
				? atEndOfDay(
						(
							columnFilters.find((filter) => filter.id === COMPLETED_AT)
								?.value as string[]
						)?.at(1),
					)?.toJSON()
				: undefined,
		},
		true,
	);

	// used for the mobile view only - needs a refactor ?
	const goToItem = useCallback(
		(item: Pick<IStepInstance, "id" | "processInstance">): void => {
			navigate({
				to: "/$workspaceId/process/$processId/gates-and-steps/$stepId",
				params: {
					workspaceId,
					processId: item.processInstance.id,
					stepId: item.id,
				},
			});
		},
		[navigate, workspaceId],
	);

	const switchPowerMode = useCallback((): void => {
		setPowerMode((prev) => !prev);
	}, []);

	// below could be simplified
	useEffect(() => {
		navigate({
			search: {
				stepId: searchParams?.stepId,
				search: globalFilter,
				page: pagination.pageIndex + 1,
				pageSize: pagination.pageSize,
				sortOrder: sorting?.[0]?.desc ? "desc" : "asc",
				sortBy: getSortByTerm(sorting?.[0]?.id),
				processIds: columnFilters?.find(
					(filter) => filter.id === PROCESS_STREAM_NAME,
				)?.value as string[],
				labelIds: columnFilters?.find((filter) => filter.id === LABELS)
					?.value as string[],
				stepTemplateIds: columnFilters?.find((filter) => filter.id === NAME)
					?.value as string[],
				nodeIds: columnFilters?.find((filter) => filter.id === OCCURRENCE)
					?.value as string[],
				responsibleIds: columnFilters?.find(
					(filter) =>
						filter.id ===
						(context === "activities" ? RESPONSIBLE_LIST : ASSIGNEE),
				)?.value as string[],
				nodeTypeIds: columnFilters?.find((filter) => filter.id === NODES_TYPE)
					?.value as string[],
				startFrom: (
					columnFilters?.find((filter) => filter.id === DUE_DATE)?.value as [
						string,
						string,
					]
				)?.at(0),
				startTo: (
					columnFilters?.find((filter) => filter.id === DUE_DATE)?.value as [
						string,
						string,
					]
				)?.at(1),
				deadlineFrom: (
					columnFilters?.find((filter) => filter.id === DEADLINE)?.value as [
						string,
						string,
					]
				)?.at(0),
				deadlineTo: (
					columnFilters?.find((filter) => filter.id === DEADLINE)?.value as [
						string,
						string,
					]
				)?.at(1),
				completedFrom: (
					columnFilters?.find((filter) => filter.id === COMPLETED_AT)
						?.value as [string, string]
				)?.at(0),
				completedTo: (
					columnFilters?.find((filter) => filter.id === COMPLETED_AT)
						?.value as [string, string]
				)?.at(1),
				timeliness: (
					columnFilters?.find((filter) => filter.id === TIMELINESS)
						?.value as ETimeliness[]
				)?.at(0),
				resolution: columnFilters?.find((filter) => filter.id === RESOLUTION)
					?.value as EResolution[],
			},
		});
	}, [
		columnFilters,
		globalFilter,
		pagination,
		sorting,
		context,
		navigate,
		searchParams?.stepId,
	]);

	const instance = useReactTable<IStepInstance>({
		data: stepInstances?.results ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
		pageCount: stepInstances?.totalPages,
		filterFns: {
			fuzzy: fuzzyFilter,
			isWithinRange,
		},
		state: {
			pagination,
			columnPinning,
			columnOrder,
			columnVisibility,
			sorting,
			columnFilters,
			globalFilter,
		},
		onPaginationChange: setPagination,
		manualPagination: true,
		manualFiltering: true,
		manualSorting: true,
		onColumnFiltersChange: (updaterOrValue: Updater<ColumnFiltersState>) => {
			setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
			setColumnFilters(updaterOrValue);
			setDirty(true);
		},
		onColumnVisibilityChange: (updaterOrValue: Updater<VisibilityState>) => {
			setColumnVisibility(updaterOrValue);
			setDirty(true);
		},
		onColumnPinningChange: (updaterOrValue: Updater<ColumnPinningState>) => {
			setColumnPinning(updaterOrValue);
			setDirty(true);
		},
		onColumnOrderChange: (updaterOrValue: Updater<ColumnOrderState>) => {
			setColumnOrder(updaterOrValue);
			setDirty(true);
		},
		onSortingChange: (updaterOrValue: Updater<SortingState>) => {
			setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
			setSorting(updaterOrValue);
			setDirty(true);
		},
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: fuzzyFilter,
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<div className="w-full">
			<DataGrid.TableHeader<IStepInstance>
				isLoading={isLoadingStepInstances}
				instance={instance}
				globalFilter={globalFilter}
				context={context}
			>
				<Button
					onClick={() => setPowerMode(!powerMode)}
					size="icon"
					variant="ghost"
				>
					{powerMode ? <Cross1Icon /> : <GearIcon />}
				</Button>
				<SelectCustomView key={preferenceKey} preferenceKey={preferenceKey} />
				<DeleteCustomView preferenceKey={preferenceKey} />
			</DataGrid.TableHeader>
			<div
				key={activeOrganization}
				className="flex flex-row overflow-auto gap-2 w-full min-h-[35vh] min-w-48"
			>
				<TableContainer>
					<DataGrid.SidePanel
						instance={instance}
						setPowerMode={switchPowerMode}
						powerMode={powerMode}
						savedViews={savedViews}
						dirty={dirty}
						setDirty={setDirty}
						preferenceKey={preferenceKey}
						activeView={activeView}
					/>
					<div className={"flex flex-col grow overflow-auto"}>
						<ErrorBoundary
							fallback={<CleanUserPreference preferenceKey={preferenceKey} />}
						>
							<DataGrid.TableBody<IStepInstance>
								instance={instance}
								powerMode={powerMode}
								goToItem={goToItem}
								isFetching={isLoadingStepInstances}
								isFetched={isFetched}
							/>
						</ErrorBoundary>
					</div>
				</TableContainer>
			</div>
			<DataGrid.Footer<IStepInstance>
				instance={instance}
				numberOfResults={stepInstances?.totalItems ?? 0}
				hasCSVExport={context === "activities"}
				setPagination={setPagination}
				isFetching={isFetching}
				isSearching={isFetching}
				isFetched={isFetched}
			/>
		</div>
	);
};
export default StepInstancesTab;
