import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { Interweave } from "interweave";
import { EmailMatcher, UrlMatcher } from "interweave-autolink";
import type React from "react";
import { useRef, useState } from "react";
import {
	type Control,
	Controller,
	type SubmitHandler,
	type UseFormRegisterReturn,
	useForm,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

import { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import Button from "@metronome/components/Button";
import { Input } from "@metronome/components/ui/input";
import type { IMetadataValue } from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { getInputType } from "@metronome/utils/metadataType";

import styles from "./editableMetadata.module.scss";

type EditableMetadataValuesProps = {
	contextId: string;
	context: Context;
	nodeId: string;
	definition: IMetadataDefinition;
	values?: IMetadataValue;
};

type EditModeMetadataValuesProps = {
	definition: IMetadataDefinition;
	control: Control<Record<string, string>>;
	registerReturn: UseFormRegisterReturn;
};

export const EditModeMetadataValues: React.FC<EditModeMetadataValuesProps> = ({
	definition,
	control,
	registerReturn,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const { ref, name, onChange, onBlur } = registerReturn;

	return (
		<form className="w-full">
			{!!["text", "email", "date", "number", "integer", "url"].includes(
				definition.type,
			) && (
				<Input
					required
					onChange={onChange}
					onBlur={onBlur}
					name={name}
					type={getInputType(definition.type)}
					ref={(e) => {
						ref(e);
						inputRef.current = e; // you can still assign to ref
					}}
					onClick={() => {
						if (getInputType(definition.type) === "date") {
							inputRef?.current?.showPicker();
						}
					}}
					onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
						e.key === "Enter" && e.preventDefault()
					}
					className={styles.input}
				/>
			)}
			{definition.type === "multiSelect" && (
				<Controller
					name={name}
					control={control}
					render={({ field: { onChange, value, ref } }) => {
						const options = definition.enum?.map((option) => ({
							label: option,
							value: option,
						}));
						return (
							<Select
								ref={ref}
								onChange={(val) => {
									onChange(val.map((c) => c.value));
								}}
								value={options?.filter((c) => value?.includes(c.value))}
								options={options}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: (base) => ({
										...base,
										zIndex: 9999,
										pointerEvents: "auto",
									}),
								}}
								isMulti
							/>
						);
					}}
				/>
			)}
			{definition.type === "singleSelect" && (
				<select
					ref={ref}
					className="w-full"
					name={name}
					onChange={onChange}
					key={definition.id}
				>
					{definition?.enum?.map((option) => (
						<option key={option}>{option}</option>
					))}
				</select>
			)}
		</form>
	);
};

export const EditableMetadataValues: React.FC<EditableMetadataValuesProps> = ({
	contextId,
	context,
	nodeId,
	definition,
	values,
}) => {
	const [editMode, setEditMode] = useState(false);
	const { register, resetField, handleSubmit, control } = useForm();

	const registerReturn = register(definition.name);

	const { mutate: mutateMetadataValues, isPending } = useUpsertMetadataValue(
		contextId,
		context,
	);

	const setupEditMode = (name: string): void => {
		setEditMode(!editMode);
		resetField(name, {
			defaultValue: values?.value,
		});
	};

	const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
		const metadataValues = [
			{
				value: data[definition.name],
				id: values?.id,
			},
		];
		mutateMetadataValues({
			definitionId: definition.id,
			nodeId,
			metadataValues,
		});
		setEditMode(false);
	};

	return (
		<div key={`definition-${definition.id}`} className={styles.container}>
			<div className="flex flex-row items-start justify-between ">
				<strong className="truncate">{definition.name}</strong>
				<div className="flex flex-row gap-2 items-center">
					{!!editMode && (
						<Button
							disabled={isPending}
							appearance="link"
							onClick={handleSubmit(onSubmit)}
						>
							<FormattedMessage id={isPending ? "LOADING" : "SAVE"} />
						</Button>
					)}
					<Button
						appearance="default"
						onClick={() => setupEditMode(definition.name)}
					>
						{editMode ? <FormattedMessage id="CANCEL" /> : <Pencil1Icon />}
					</Button>
				</div>
			</div>
			<div className="flex flex-row gap-1 flex-wrap">
				{!editMode && typeof values?.value === "string" && (
					<Interweave
						key={`metadata-${values.id}`}
						content={values?.value}
						matchers={[new UrlMatcher("url"), new EmailMatcher("email")]}
					/>
				)}

				{!editMode && Array.isArray(values?.value) && (
					<span>{values?.value.join(", ")}</span>
				)}

				{!values?.value && (
					<span className="italic">
						<FormattedMessage id="METADATA_NO_VALUE" />
					</span>
				)}
				{!!isPending && (
					<FontAwesomeIcon
						spin
						style={
							isPending ? { color: "lightgray" } : { color: "transparent" }
						}
						icon={["fas", "spinner"]}
					/>
				)}
				{!!editMode && (
					<EditModeMetadataValues
						definition={definition}
						registerReturn={registerReturn}
						control={control}
					/>
				)}
			</div>
		</div>
	);
};
